export default class {
  #formGroup
  #inputs

  constructor(formGroup, inputs) {
    this.#formGroup = formGroup
    this.#inputs = inputs
    this.inputEvents = {
      text: 'blur',
      checkbox: 'change',
      radio: 'change',
      file: 'change',
      'select-one': 'change',
      textarea: 'blur'
    }
  }

  render() {
    this.#showErrorMessage()
    this.#prepareInputsForAnswer()
  }

  #showErrorMessage() {
    if (!this.#formGroup.querySelector('.error-label')) {
      this.#inputs.forEach(input => { input.classList.add('invalid') })
      const errorLabel = document.createElement('span')
      errorLabel.classList.add('error-label', 'd-block', 'mt-1')
      errorLabel.textContent = 'El campo es requerido'
      this.#formGroup.appendChild(errorLabel)
    }
  }

  #prepareInputsForAnswer() {
    const inputsType =  this.#findInputsType(this.#inputs)
    this.#inputs.forEach(input => {
      input.addEventListener(this.inputEvents[inputsType], _event => {
        this.#inputs.forEach(input => input.classList.remove('invalid'))
        this.#formGroup.querySelector('.error-label')?.remove()
      })
    })
  }

  #findInputsType(inputs) {
    return [...new Set(inputs.map(input => input.type))].pop()
  }
}
