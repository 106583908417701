import BaseValidator from "./base_validator"

export default class NumericalityValidator extends BaseValidator {
  validate() {
    let valid = true
    let errorMessage = ''

    if (!this.value || this.value === '') {
      valid = false
      errorMessage = this.errorMessages['not_a_number']
    } else {
      let number = Number(this.value)

      Object.keys(this.options).some((key) => {
        let result = this.verifyNumber(number, key, this.options[key])
        if (!result) {
          valid = result
          errorMessage = this.getErrorMessage(key)
          return !result
        }
      })
    }

    return { valid: valid, message: errorMessage }
  }

  verifyNumber(number, option, value) {
    if (option === 'only_integer' && value) {
      return Number.isInteger(number)
    } else if (option === 'greater_than') {
      return number > value
    } else {
      false
    }
  }

  getErrorMessage(option) {
    let errorKey = option

    if (option === 'only_integer') {
      errorKey = 'not_an_integer'
    }

    return this.errorMessages[errorKey]
  }
}
