import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener('change', e => {
      this.set_visibility(e)
    })
  }

  set_visibility(e){
    let report_comment = document.getElementById('report_more_info')
    if (e.target.value == "waiting_for_promoter"){
      report_comment.classList.remove('d-none')
    }
    else{
      report_comment.classList.add('d-none')
    }
  }
}