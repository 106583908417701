export default class {
  #element
  #pages
  #currentPage

  constructor(element) {
    this.#element = element
    this.#pages = this.#element.getElementsByClassName('page')
    this.#currentPage = 0
    this.#showPage(this.#currentPage)
  }

  updatePage(pageNumber) {
    this.#pages[this.#currentPage].style.display = 'none'
    if(this.#canUpdate(pageNumber))
      this.#currentPage = this.#currentPage + pageNumber

    this.#showPage(this.#currentPage)
  }

  isFirstPage() {
    return this.#currentPage === 0
  }

  isLastPage() {
    return this.#currentPage === (this.#pages.length - 1)
  }

  getCurrentPageElement() {
    return this.#pages[this.#currentPage]
  }

  #showPage(pageNumber) {
    this.#pages[pageNumber].style.display = 'block'
  }

  #canUpdate(requestedPage) {
    const nextPage = this.#currentPage + requestedPage
    return (nextPage >= 0 && nextPage <= (this.#pages.length - 1))
  }
}
