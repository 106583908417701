export default class {
  #element
  #controlsContainer
  #previewBtn
  #eraseBtn
  #captureBtn
  #loadBtn

  constructor(element, controlsContainer) {
    this.#element = element
    this.#controlsContainer = controlsContainer
    this.#createButtons()
  }

  bindButtonActions(buttons, actions) {
    buttons.forEach(btn => {
      const button = this.buttons[btn]
      actions[btn].forEach((params) => {
        button.addEventListener(params[0], event => { params[1](event, params[2]) })
      })
    })
  }

  renderButtons(requiredButtons) {
    requiredButtons.forEach(btn => this.#controlsContainer.appendChild(this.buttons[btn]))
  }

  removeButtons(targets) {
    targets.forEach(btn => {
      const button = this.buttons[btn]
      this.#controlsContainer.removeChild(button)
    })
  }

  get buttons() {
    return {
      previewable: this.#previewBtn,
      eraseable: this.#eraseBtn,
      capturable: this.#captureBtn,
      loadable: this.#loadBtn
    }
  }

  #createButtons() {
    this.#previewBtn = this.#createBtn('previewable')
    this.#eraseBtn = this.#createBtn('eraseable')
    this.#captureBtn = this.#createBtn('capturable')
    this.#loadBtn = this.#createBtn('loadable')
  }

  #createBtn(type) {
    const btn = document.createElement('a')
    btn.setAttribute('href', 'javascript:void(0)')
    btn.setAttribute('data-button-action', type)
    btn.classList.add('control')
    btn.appendChild(this.#createImage(type))
    return btn
  }

  #createImage(type) {
    const img = document.createElement('img')
    img.setAttribute('width', '30px')
    img.setAttribute('src', this.#icons[type])
    return img
  }

  get #icons() {
    return {
      previewable:  this.#element.dataset.btnPreviewIcon,
      eraseable: this.#element.dataset.btnEraseIcon,
      capturable: this.#element.dataset.btnCaptureIcon,
      loadable: this.#element.dataset.btnLoadIcon
    }
  }
}
