import { Controller } from "@hotwired/stimulus"
import { Collapse } from "bootstrap"

export default class extends Controller {
  connect() {
    this.collapsable = new Collapse(`#${this.element.dataset.targetId}`, { toggle: false })
  }

  toggleSection(event) {
    const button = event.currentTarget
    button.classList.toggle('collapsed')
    this.collapsable.toggle()
  }
}
