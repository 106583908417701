import Validations from "./validations.js.erb"
import BaseValidator from "./base_validator"
import PresenceValidator from "./presence_validator"
import LengthValidator from "./length_validator"
import NumericalityValidator from "./numericality_validator"
import FormatValidator from "./format_validator"
import UniquenessValidator from "./uniqueness_validator"

let validators = {
  presence: PresenceValidator,
  numericality: NumericalityValidator,
  length: LengthValidator,
  format: FormatValidator,
  uniqueness: UniquenessValidator
}

export class Validation {
  validatable(input_id) {
    return Validations[input_id] !== undefined
  }

  validate(value, id, newRecord) {
    let validations = Validations[id]
    let results = validations.map((validation) => {
      let validator = new validators[validation.validator](value, validation.options, validation.error_messages, id, newRecord)
      return { validator: validation.validator, result: validator.validate() }
    });

    return results
  }
}
