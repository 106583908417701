import { Validation } from "../validators"
import Validations from "../validators/validations.js.erb"
import { events } from "../utils/events"
import { FormValidationController } from "./form_validation_controller"

export default class extends FormValidationController {

  connect() {
    this.newRecord = true
    this.eventForInputType = events
    this.validation = new Validation()
    this.validatableInputs = []
    this.errors = {}
    this.bindInputs()
    this.bindSelects()
    this.bindForm()
  }

  bindInputs() {
    let inputs = this.element.querySelectorAll('input')
    inputs.forEach((input) => {
      let input_name = input.getAttribute('id')
      if (input_name) {
        input_name = input_name.replace(/_true/g, '').replace(/_false/g, '')
                               .replace(/_female/g, '').replace(/_male/g, '')
        let validate = this.validation.validatable(input_name.replace(/_[0-9]$/g,''))
        if (validate) {
          input.addEventListener(this.eventForInputType[`${input.type}`], (event) => {
            this.handleValidatableSection(event)
            this.validate(event)
          })
          this.validatableInputs.push(input)
        }
      }
    })
  }

  bindSelects() {
    let selects = this.element.querySelectorAll('select')
    selects.forEach((select) => {
      let input_name = select.getAttribute('id')
      if(input_name) {
        let validate = this.validation.validatable(input_name.replace(/_[0-9]$/g,''))
        if(validate) {
          select.addEventListener(this.eventForInputType['select'], (event) => {
            this.handleValidatableSection(event)
            this.validate(event)
          })
          this.validatableInputs.push(select)
        }
      }
    })
  }

  handleValidatableSection(event) {
    const section = event.target.closest('fieldset').closest('.validatable-section')

    if( section && section.dataset.validatable === 'false') {
      section.setAttribute('data-validatable', 'true')
    }
  }

  validate(event) {
    if(this.validatableSection(event.target)) {
      return
    }
    let inputValue = this.inputValue(event.target)
    let inputId = event.target.getAttribute('id').replace(/_[0-9]$/g, '')
                       .replace(/_true/g, '').replace(/_false/g, '')
                       .replace(/_female/g, '').replace(/_male/g, '')
    let validationResult = this.validation.validate(inputValue, inputId, this.newRecord)

    let validator = validationResult.find((validator) => !validator.result.valid)
    this.errors[inputId] = !!validator
    this.render(event.target, validationResult)

    if(typeof(validator) === 'undefined') {
      validationResult = this.validation.validate(inputValue, inputId.replace('prospect', 'user'), this.newRecord)
      validator = validationResult.find((validator) => !validator.result.valid)
      this.errors[inputId] = !!validator
      this.render(event.target, validationResult)
    }
  }

  validatableSection(target) {
    const section = target.closest('fieldset').closest('.validatable-section')

    return !(section && section.dataset.validatable === 'true')
  }

  render(target, validation) {
    let validator = validation.find((validator) => !validator.result.valid)
    if (validator) {
      this.renderValidation(target, validator)
    } else {
      const options = Array.from(document.querySelectorAll(`input[name='${target.name}']`))
      if(options.length > 1) {
        options.forEach(input => this.clearValidation(input, validator))
      } else {
        this.clearValidation(target, validator)
      }
    }
  }

  clearValidation(target, validator) {
    target.classList.remove('invalid')

    let help = target.closest('fieldset').querySelector('.error-label')
    help.classList.remove('show')
    help.innerHTML = ''
  }

  renderValidation(target, validator) {
    target.classList.add('invalid')
    let help = target.closest('fieldset').querySelector('.error-label')
    help.classList.add('show')
    help.innerHTML = `${validator.result.message}<br>`
  }
}
