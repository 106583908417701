import { dataURLtoBlob } from '../helpers'

export default class {
  constructor(file, compressionFactor = 0.9) {
    this.file = file
    this.compressionFactor = compressionFactor
    this.canvas = document.createElement("canvas");
  }

  async compressImage() {
    let dimenssions = await this.getDimensions(this.file)
    this.canvas.width = dimenssions.width * this.compressionFactor;
    this.canvas.height = dimenssions.height * this.compressionFactor;
    const compresed = await new Promise(resolve => {
      const img = new Image()
      img.onload = () =>  { resolve(this.canvas.getContext('2d').drawImage(img, 0, 0)) }
      img.src = window.URL.createObjectURL(this.file)
    })

    const blob = dataURLtoBlob(this.canvas.toDataURL('image/webp'))
    const newFileName = this.file.name.split('.')[0]
    const newFile = new File([blob], `${newFileName}.webp`, {type: "image/webp"})
    return newFile
  }

  async getDimensions(image) {
    const img = new Image()
    const url = window.URL.createObjectURL(image)
    const dimensions = await this.getWidthAndHeight(url)
    return dimensions
  }

  getWidthAndHeight(imgUrl) {
    return new Promise(resolve  => {
      const img = new Image()
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width
        })
      }
      img.src = imgUrl
    })
  }
}
