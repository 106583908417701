import { Controller } from "@hotwired/stimulus"
import PreviewManager from './utils/preview_manager'

export default class extends Controller {
  connect() {
    this.frameManager = new PreviewManager(this.element, '.preview')
    this.element.addEventListener('image:content-change', () => { this.renderPreview() })
    this.renderPreview()
  }

  renderPreview() {
    if (this.frameManager.isCompleted()) {
      this.frameManager.renderImage()
    } else {
      this.frameManager.removeImage()
    }
  }
}
