import BaseValidator from "./base_validator"

export default class UniquenessValidator extends BaseValidator {
  validate() {
    let valid = true
    let errorMessage = ''
    let modelName = this.id.split('_')[0]
    let attributeName = this.id.replace(`${modelName}_`, '')
    let url = `/validations?model=${modelName}&field=${attributeName}&value=${encodeURIComponent(this.value)}`

    if(this.newRecord !== '' && this.newRecord === 'true') {
      let xhr = new XMLHttpRequest()
      xhr.open('GET', url, false)
      xhr.overrideMimeType('application/json')
      xhr.send(null)
      let response = JSON.parse(xhr.responseText)
      if(!response.result.uniq) {
        valid = false
        errorMessage = this.errorMessages['taken']
      }
    }

    return { valid: valid, message: errorMessage }
  }
}
