import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['input']

  connect() {
    this.togglePassword()
  }

  togglePassword(){
    let button = this.element.querySelector('.password-toggle-text');
    let target = this.inputTarget

    button.addEventListener("click", function() {
      if (target.type === "password") {
        button.textContent = "Ocultar"
        target.type = "text"
      } else {
        button.textContent = "Mostrar"
        target.type = "password"
      }
    });
  }
}
