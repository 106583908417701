/*
 * Primordialmente en una entrevista lo que se evalua es que las preguntas obligatorias sean
 * respondidas asi que el objetivo de este componente es ese. Pero dado que la estructura del
 * formulario es mas compleja es necesario validar la presencia de respuesta de manera particular
*/

export default class {
  #validations

  constructor() {
    this.#validations = {
      text: this.#textValidation,
      checkbox: this.#checkValidation,
      radio: this.#radioValidation,
      file: this.#fileValidation,
      'select-one': this.#selectValidation,
      textarea: this.#textAreaValidation
    }
  }

  validateSection(section) {
    // initialize error in blank
    this.errors = []
    // Get all section questions
    const questions =  Array.from(section.querySelectorAll('.answer-detail'))
    questions.forEach(question => {
      if(question.dataset.answerRequired == 'true') {
        // Each section has a question to be answered
        const answerDetail = question.querySelector('.answer')
        // Check type and if has been answered
        this.#evaluateAnswerDetail(answerDetail)
      }
    })
    // Return found errors
    return this.errors
  }

  #evaluateAnswerDetail(answerDetail) {
    // Get input to validate it has been aswered
    const inputs = this.#getAnswerableInputs(answerDetail)

    if(!this.#isValid(inputs)) {
      this.errors.push([answerDetail, inputs])
    }
  }

  #getAnswerableInputs(container) {
    const selectors = ['input', 'select', 'textarea']
    let inputs = []
    for(const selector of selectors) {
      inputs = Array.from(container.querySelectorAll(`${selector}[data-answer-field="true"]`))
      if(inputs.length > 0) return inputs
    }

    return inputs
  }

  #isValid(inputs) {
    const inputsType = this.#findInputsType(inputs)
    const validator =  this.#validations[inputsType]

    return validator(inputs)
  }

  #findInputsType(inputs) {
    return [...new Set(inputs.map(input => input.type))].pop()
  }

  #textValidation(inputs) {
    const value = inputs[0].value.trim()
    return value !== ''
  }

  #checkValidation(inputs) {
    return inputs.filter(input => input.checked).length > 0
  }

  #radioValidation(inputs) {
    return inputs.filter(input => input.checked).length > 0
  }

  #fileValidation(inputs) {
    return inputs.filter(input => input.dataset.fileAttached == 'true').length > 0
  }

  #selectValidation(inputs) {
    return inputs.filter(input => input.value !== '').length > 0
  }

  #textAreaValidation(inputs) {
    const value = inputs[0].value.trim()
    return value !== ''
  }
}
