import ModalBuilder from '../utils/modal_builder'

export default class CameraScreenshotModal extends ModalBuilder {
  constructor(title = 'Screenshot modal', modalBodyContent = '<p></p>') {
    super(title, modalBodyContent)
    this.video = this.setVideo()
    this.canvas = this.setCanvas()
    this.customizeModal()
  }

  customizeModal() {
    this.modalContainer.setAttribute('data-camera-screenshot-target', 'modal')
    this.modalHeader.classList.add('border-bottom-0')
    this.modalHeader.classList.add('pb-0')
    this.modalFooter.classList.add('justify-content-center')
    this.setContent()
  }

  setContent() {
    this.modalBody.innerHTML = this.content().outerHTML
    this.modalFooter.appendChild(this.setModalButton())
  }

  content() {
    const row = this.row()
    const column = this.column()
    const videoWrapper = this.videoWrapper()
    videoWrapper.appendChild(this.video)
    videoWrapper.appendChild(this.frame())
    column.appendChild(videoWrapper)
    column.appendChild(this.canvas)
    row.appendChild(column)
    return column
  }

  div() {
    return document.createElement('div')
  }

  row() {
    const row = this.div()
    row.classList.add('row')
    return row
  }

  column() {
    const column = this.div()
    column.classList.add('col-md-12')
    return column
  }

  videoWrapper() {
    const videoWrapper = this.div()
    videoWrapper.classList.add('video-wrapper')
    return videoWrapper
  }

  frame() {
    const frame = this.div()
    frame.setAttribute('id', 'frame')
    return frame
  }

  setVideo() {
    const video = document.createElement('video')
    video.setAttribute('data-camera-screenshot-target', 'video')
    video.style.width = '100%'
    video.autoplay = true
    return video
  }

  setCanvas() {
    const canvas = document.createElement('canvas')
    canvas.setAttribute('data-camera-screenshot-target', 'canvas')
    canvas.style.width = '100%'
    canvas.style.display = 'none'
    return canvas
  }

  setModalButton() {
    const button = document.createElement('button')
    button.setAttribute('type', 'button')
    button.setAttribute('data-action', 'camera-screenshot#takeScreenshot')
    button.classList.add('btn')
    button.classList.add('button')
    button.classList.add('button-primary')
    button.classList.add('take-screenshot')
    button.innerHTML = 'Tomar foto'
    return button
  }

  getVideo() {
    return this.video
  }
}
