import { Controller } from "@hotwired/stimulus"
export default class extends Controller {

  connect() {
    this.element.addEventListener("click", () => {
      this.copyToClipboard(this.element.dataset.url)
    })
  }

  copyToClipboard(textToCopy) {
    navigator.clipboard.writeText(textToCopy).then(() => {
      alert(`URL copiada al portapapeles: ${textToCopy}`)
    })
    .catch(() => {
      alert("No se pudo copiar el url en el portapapeles");
    });
  }
}
