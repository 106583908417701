import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect(){
    this.checkbox_container = this.element.closest('fieldset')
    this.input = this.checkbox_container.querySelector("#status") ||  this.checkbox_container.querySelector("#status_2") ||  this.checkbox_container.querySelector("#content_type_2")
    if (this.element.checked){
      this.checkbox_container.classList.toggle("active")
    }
  }
  
  setActive(e){
    document.querySelectorAll('fieldset').forEach(x => x.classList.remove("active"))
    this.checkbox_container.classList.toggle("active")
    
    if (this.input){
      this.input.value = e.target.value
    }
  }
}
