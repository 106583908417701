import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const tab = document.querySelector('.tab-pane.show.fade.active')
    const form = tab.querySelector('form')

    const broadcastEvent = new CustomEvent('broadcast', { bubbles: true })
    form.dispatchEvent(broadcastEvent)
    this.element.remove()
  }
}
