import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    
    let submenu_conincidencias = document.querySelector(".submenu-conincidencias")
    if (submenu_conincidencias){
      let item_infoprospecto = document.querySelector(".submenu-conincidencias .item-infoprospecto")
      let item_coincidencias = document.querySelector(".submenu-conincidencias .item-coincidencias")
      let container_info_prospecto = document.querySelector(".container-info-prospecto")
      let container_info_coincidencias = document.querySelector(".container-info-coincidencias")
      item_infoprospecto.addEventListener('click', function (e) {
        this.classList.add("active")
        container_info_prospecto.classList.add("active")
        item_coincidencias.classList.remove("active")
        container_info_coincidencias.classList.remove("active")
      });
      item_coincidencias.addEventListener('click', function (e) {
        this.classList.add("active")
        container_info_coincidencias.classList.add("active")
        item_infoprospecto.classList.remove("active")
        container_info_prospecto.classList.remove("active")
      });
    }
    
    let sidebar_dictaminar = document.querySelector(".sidebar-dictaminar")
    if (sidebar_dictaminar){
      let active_sidebar_dictaminar = document.querySelectorAll(".active-sidebar-dictaminar")
      let close_sidebar_dictaminar = document.querySelector(".close-sidebar-dictaminar")
      let sidebar_dictaminar_backdrop = document.querySelector(".sidebar-dictaminar-backdrop")
      for (let i = 0; i < active_sidebar_dictaminar.length; i++) {
        active_sidebar_dictaminar[i].addEventListener('click', function (e) {
          sidebar_dictaminar.classList.add("active")
          sidebar_dictaminar_backdrop.classList.add("active")
        });
      }
      close_sidebar_dictaminar.addEventListener('click', function (e) {
        sidebar_dictaminar.classList.remove("active")
        sidebar_dictaminar_backdrop.classList.remove("active")
      });
    }
  }
}