import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = {
    zipCode: { type: String, default: '' },
    neighborhood: { type: String, default: '' },
    city: { type: String, default: '' },
    state: { type: String, default: '' },
    country: { type: String, default: 'México' }
  }

  static targets = ['code', 'neighborhoodSelector', 'city', 'state', 'country']

  connect() {
    this.codeTarget.addEventListener('change', (event) => {
      const code = event.currentTarget.value
      this.clearFields()
      this.handleCode(code)
    })
    if(this.codeTarget.value !== ''){
      this.clearFields()
      this.handleCode(this.zipCodeValue)
      this.setPersistedData()
    }
  }

  loadNeighborhoods(event) {
    let selectedData = this.fetchStateAndCity(event.currentTarget.value, this.responseData)
    this.renderStateAndCity(selectedData)
  }

  async handleCode(code) {
    let data = await this.queryCode(code)
    this.responseData = JSON.parse(data)
    this.renderNeighborhoods()
  }

  renderNeighborhoods() {
    let firstOption = document.createElement('option')
    firstOption.selected = false
    firstOption.innerHTML = 'Selecciona una opcion'
    this.neighborhoodSelectorTarget.appendChild(firstOption);
    this.responseData.forEach(record => {
      let opt = this.createOption(record)
      this.neighborhoodSelectorTarget.appendChild(opt);
    })
  }

  createOption(args) {
    let element = document.createElement('option')
    element.value = args.colonia
    element.innerHTML = args.colonia
    element.selected = args.colonia === this.neighborhoodValue
    return element
  }

  fetchStateAndCity(neighborhood, data) {
    let selected = data.find(d => d.colonia === neighborhood )
    if(!selected) {
      return {
        colonia: '',
        municipio: '',
        estado: ''
      }
    }
    return selected
  }

  renderStateAndCity(data) {
    const cityContainer = this.city.closest('.form-group')
    const stateContainer = this.state.closest('.form-group')
    cityContainer.classList.remove('d-none')
    stateContainer.classList.remove('d-none')
    this.city.value = data.municipio
    this.state.value = data.estado
    this.country.value = this.countryValue
  }

  queryCode(code) {
    let url = `/zip_codes?code=${code}`
    return get(url).then(resp => resp.json )
  }

  setPersistedData() {
    const cityContainer = this.city.closest('.form-group')
    const stateContainer = this.state.closest('.form-group')
    cityContainer.classList.remove('d-none')
    stateContainer.classList.remove('d-none')
    this.city.value = this.cityValue
    this.state.value = this.stateValue
    this.country.value = this.countryValue
  }

  clearFields() {
    this.neighborhoodSelectorTarget.options.length = 0
    this.neighborhoodSelectorTarget.value = null
    this.city.value = null
    this.state.value = null
    this.country.value = null
  }

  get city() {
    return this.cityTarget
  }

  get state() {
    return this.stateTarget
  }

  get country() {
    return this.countryTarget
  }
}
