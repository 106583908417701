import { Controller } from "@hotwired/stimulus"
import ValidationsHandler from "../shared/issues/validations_handler"
import ErrorsNotifier from "../shared/forms/errors_notifier"

export default class extends Controller {
  #invalidFields

  validate(event) {
    event.preventDefault()
    this.#validateInputs()
    this.#handleErrorsOrSubmit()
  }

  #handleErrorsOrSubmit() {
    if(this.#invalidFields.length > 0) {
      this.#invalidFields.forEach(error => { new ErrorsNotifier(error[0], error[1]).render() })
    } else {
      this.element.submit()
    }
  }

  #validateInputs() {
    const validableElements = this.#getValidableElements()
    this.#invalidFields = []
    validableElements.forEach(element => {
      const validationHandler = new ValidationsHandler(element)
      if (!validationHandler.isValid()) {
        const formGroup = element.dataset.validableType == 'image' ? element.parentNode.parentNode : element.parentNode
        this.#invalidFields.push([formGroup, [element]])
      }
    })
  }

  #getValidableElements() {
    return this.element.querySelectorAll("[data-validable='true']")
  }
}
