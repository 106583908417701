import { Controller } from "@hotwired/stimulus";
import Toast from '../utils/toast';
import { updateClassOnElements, numberToCurrency } from '../helpers';

export default class CarsSelectionController extends Controller {
  static targets = [
    'tabSelector', 'brandSelector', 'fuelSelector', 'modalTrigger',
    'modal', 'closeButton', 'applyButton', 'modelSelector', 'yearSelector', 'periodsSelector',
    'fuelType', 'guaranteeDeposit', 'weeklyPayment', 'periods', 'transmission',
    'leasingProductId'
  ];

  static values = {
    years: Array,
    periods: Array,
    fuels: { type: Array, default: ['gasoline', 'gnv'] }
  };

  initialize() {
    this.setQueryDefaults()
    this.bindAllElements()
    this.updateSearchCriteria('brand', this.element.dataset.preselectedBrand || this.brandSelectorTarget.options[0].dataset.make);
    this.updateSearchCriteria('fuel', this.element.dataset.preselectedFuel || '');
    this.updateSearchCriteria('year', this.element.dataset.preselectedYear || '');
    this.updateSearchCriteria('periods', this.element.dataset.preselectedPeriods || '');
    this.updateSearchCriteria('model', this.element.dataset.preselectedModel || '');
    this.updateSearchCriteria('version', this.element.dataset.preselectedVersion || '');
    this.updateSearchCriteria('guarantee_deposit', this.element.dataset.preselectedGuaranteeDeposit || '');

    this.setUpDefaultCarsState();
    this.activateLeasingProductModel();
    const activeTab = this.element.querySelector('.box-cotizacion-item > .tab-pane.active');

    this.periodsTarget.value = this.element.dataset.preselectedPeriods || activeTab.dataset.tabPeriods || '';
    this.fuelTypeTarget.value = this.element.dataset.preselectedFuel || activeTab.dataset.tabFuel || '';
    this.guaranteeDepositTarget.value = this.element.dataset.preselectedGuaranteeDeposit || activeTab.querySelector('input.guarantee-deposit').value.replace(',', '')  || '';
    this.weeklyPaymentTarget.value = this.element.dataset.preselectedWeeklyPayment || activeTab.querySelector('p.weekly-payment').dataset.weeklyPayment.replace(',', '') || '';
    this.transmissionTarget.value = this.element.dataset.preselectedTransmission || activeTab.dataset.tabTransmission || '';
    this.leasingProductIdTarget.value = this.element.dataset.preselectedLeasingProductId || activeTab.dataset.tabLeasingProduct || '';
  }

  bindAllElements() {
    this.fuelSelectorTargets.forEach((trigger) => {
      trigger.addEventListener('click', (event) => {
        event.preventDefault();
        const target = event.currentTarget;
        this.updateSearchCriteria('fuel', target.dataset.fuelValue);
        this.fuelTypeTarget.value = target.dataset.fuelValue;
        updateClassOnElements(this.fuelSelectorTargets, 'active', 'remove');
        target.classList.add('active');
        this.updateTab();
      });
    });
    this.yearSelectorTarget.addEventListener('change', (event) => {
      this.updateSearchCriteria('year', event.currentTarget.value);
      this.updateTab();
    });
    this.bindSelectors(this.periodsSelectorTargets, 'periods');
    this.bindTabSelectors();
  }

  setUpDefaultCarsState() {
    this.updateModelOptions();
    this.updateVersionOptions();
    this.updateYearsOptions();
    this.updateFuelOptions();
    this.updateTab();
  }

  setQueryDefaults() {
    const queryDefaults = [['brand', ''], ['fuel', ''], ['year', ''],
      ['periods', ''], ['model', ''], ['version', ''], ['guarantee_deposit', ''],
      ['new_payment', '']];
    this.searchCriteria = new Map(queryDefaults);
  }

  updatePriceContainer(e){
    const container_guarantee_deposit = document.querySelectorAll('.price-container .image-radio__pricing .container-guarantee-deposit')
    const container_weekly_payment = document.querySelectorAll('.price-container .image-radio__pricing .container-weekly-payment')
    const container_periods = document.querySelectorAll('.price-container .image-radio__select .container-periods')
    container_guarantee_deposit.forEach((element) => {
      element.innerHTML = (this.guaranteeDepositTarget.value) + " mxn"
    });
    container_weekly_payment.forEach((element) => {
      element.innerHTML = (this.weeklyPaymentTarget.value) + " mxn"
    });
    container_periods.forEach((element) => {
      element.innerHTML = (this.periodsTarget.value) + " meses"
    });
  }

  bindTabSelectors() {
    document.body.addEventListener('pricing:updated', (event) => {
      const activeTab = this.element.querySelector('.box-cotizacion-item > .tab-pane.active');
      const button = activeTab.querySelector('.obtener_mi_auto')
      if (!button) return;

      let url = new URL(button.form.action);
      url.searchParams.set('guarantee_deposit', event.detail.guaranteeDeposit);
      url.searchParams.set('weekly_payment', event.detail.weeklyPayment);
      button.form.action = url.href;
    })

    this.makeSelector = document.getElementById('make-selector');
    this.makeOptions = Array.from(this.makeSelector.options);
    this.makeSelector.value = this.element.dataset.preselectedBrand || this.brandSelectorTarget.options[0].dataset.make
    this.makeSelector.addEventListener('change', (event) => {
      event.preventDefault();
      const makeSelected = [...event.currentTarget.options].find((option) => option.selected);
      this.updateSearchCriteria('brand', makeSelected.dataset.make);
      this.updateModelOptions();
      this.updateVersionOptions();
      this.updateFuelOptions();
      this.updateYearsOptions();
      this.updateTab();
    });

    this.modelSelector = document.getElementById('model-selector');
    this.modelOptions = Array.from(this.modelSelector.options);
    this.modelSelector.addEventListener('change', (event) => {
      event.preventDefault();
      this.updateSearchCriteria('model', this.modelSelector.options[this.modelSelector.selectedIndex].dataset.modelName);
      this.updateVersionOptions();
      this.updateFuelOptions();
      this.updateYearsOptions();
      this.updateTab();
    });

    this.versionSelector = document.getElementById('version-selector');
    this.versionOptions = Array.from(this.versionSelector.options);
    this.versionSelector.addEventListener('change', (event) => {
      event.preventDefault();
      const option = this.versionSelector.options[this.versionSelector.selectedIndex];
      this.updateSearchCriteria('version', option.value);
      this.updateYearsOptions();
      this.updateTab();
    });
  }

  getQuery() {
    let query = '';
    if (!this.searchCriteria.get('brand') == '') query += `[data-tab-brand="${this.searchCriteria.get('brand')}"]`;
    if (!this.searchCriteria.get('fuel') == '') query += `[data-tab-fuel="${this.searchCriteria.get('fuel')}"]`;
    if (!this.searchCriteria.get('year') == '') query += `[data-tab-year="${this.searchCriteria.get('year')}"]`;
    if (!this.searchCriteria.get('periods') == '') query += `[data-tab-periods="${this.searchCriteria.get('periods')}"]`;
    if (!this.searchCriteria.get('model') == '') query += `[data-tab-model="${this.searchCriteria.get('model')}"]`;
    if (!this.searchCriteria.get('version') == '') query += `[data-tab-version="${this.searchCriteria.get('version')}"]`;
    return query;
  }

  bindSelectors(elements, key) {
    elements.forEach((element, index) => {
      element.addEventListener('change', (event) => {
        this.updateSearchCriteria(key, event.currentTarget.value);
        this.updateTab();
        event.currentTarget.value = event.currentTarget.dataset.initialValue;
      });
    });
  }

  updateFuelOptions() {
    const currentFuelOptions = [...this.element.querySelectorAll(`[data-tab-model="${this.searchCriteria.get('model')}"]`)];
    let uniqFuelValues = [...new Set(currentFuelOptions.map((element) => element.dataset.tabFuel))];
    this.fuelSelectorTargets.forEach((element) => {
        if (!uniqFuelValues.includes(element.dataset.fuelValue)) {
            element.parentNode.classList.add('d-none');
        } else {
            element.parentNode.classList.remove('d-none');
        }
    });

    const preselectedFuel = this.element.dataset.preselectedFuel;
    const fuelSelected = uniqFuelValues.includes(preselectedFuel) ? preselectedFuel : uniqFuelValues[0];

    this.updateSearchCriteria('fuel', fuelSelected);
    this.fuelTypeTarget.value = fuelSelected;

    this.fuelSelectorTargets.forEach((element) => {
        element.classList.remove('active');
        if (element.dataset.fuelValue === fuelSelected) element.classList.add('active');
    });
  }

  updateModelOptions() {
    Array.from(this.modelSelector.options).forEach((element) => element.remove());
    const brand = this.searchCriteria.get('brand') || this.element.dataset.preselectedBrand || '';
    let pillsToShow = this.filterCollection(this.modelOptions, 'brandName', brand);
    const preselectedModel = this.element.dataset.preselectedModel;

    if (pillsToShow.length === 0) return;

    pillsToShow.forEach((element) => {
      this.modelSelector.appendChild(element);
    });

    const selectedOption = [...this.modelSelector.options].find((option) => option.dataset.modelName === preselectedModel) || pillsToShow[0];
    selectedOption.selected = true;
    this.updateSearchCriteria('model', selectedOption.dataset.modelName);
  }

  updateVersionOptions() {
    Array.from(this.versionSelector.options).forEach((element) => element.remove());
    const model = this.searchCriteria.get('model') || this.element.dataset.preselectedModel || '';
    let optionsToShow = this.versionOptions;
    optionsToShow = this.filterCollection(optionsToShow, 'modelName', model);
    const preselectedVersion = this.element.dataset.preselectedVersion;

    if (optionsToShow.length === 0) return;

    optionsToShow.forEach((element) => {
        this.versionSelector.appendChild(element);
    });

    const selectedOption = optionsToShow.find((option) => option.value === preselectedVersion) || optionsToShow[0];
    selectedOption.selected = true;
    this.updateSearchCriteria('version', selectedOption.value);
  }

  updateYearsOptions() {
    const optionSelected = [...this.versionSelector.options].find((option) => option.selected);
    const years = optionSelected.dataset.modelYears.split(',').sort((a, b) => a - b).reverse();

    Array.from(this.yearSelectorTarget.options).forEach((option) => option.remove());

    years.forEach((year) => {
      this.yearSelectorTarget.add(new Option(year, year), undefined);
    });
    this.updateSearchCriteria('year', years[0]);
  }

  filterCollection(collection, attribute, value) {
    return value !== ''
      ? collection.filter((element) => element.dataset[attribute] === value)
      : collection;
  }

  updateSearchCriteria(key, value) {
    this.backupCriteria = new Map([...this.searchCriteria]);
    this.searchCriteria.set(key, value);
  }

  updateTab() {
    const activeTab = this.element.querySelector('.box-cotizacion-item > .tab-pane.active');
    let targetTab = this.element.querySelector(this.getQuery());

    if (targetTab === null) {
      const errorMessage = new Toast('No hay autos con estas características', true);
      errorMessage.show();
      this.searchCriteria = new Map(this.backupCriteria);
      targetTab = this.element.querySelector(this.getQuery());
    }

    if (activeTab) {
      activeTab.classList.remove('active', 'show');
    }
    if (targetTab) {
      targetTab.classList.add('show', 'active');
    }
    this.updateCarSelectedData(targetTab);
  }

  updateCarSelectedData(tabSelected) {
    if (tabSelected) {
      const guaranteeDepositField = tabSelected.querySelector('.guarantee-deposit');
      const weeklyPaymentField = tabSelected.querySelector('.weekly-payment');
      const periodsField = tabSelected.querySelector('.periods option:checked');

      let guarantee_deposit_value = 0
      let weekly_payment_value = 0
      let periods_value = 0

      if (tabSelected.dataset.tabLeasingProduct == this.element.dataset.preselectedLeasingProductId) {
        guarantee_deposit_value = parseFloat(this.element.dataset.preselectedGuaranteeDeposit)
        weekly_payment_value = parseFloat(this.element.dataset.preselectedWeeklyPayment)
        periods_value = parseFloat(this.element.dataset.preselectedPeriods)
      }
      else{
        guarantee_deposit_value = parseFloat(guaranteeDepositField.dataset.guaranteeDeposit.replace(',', ''))
        weekly_payment_value = parseFloat(weeklyPaymentField.dataset.weeklyPayment.replace(',', ''))
        periods_value = parseFloat(periodsField.dataset.periods.replace(',', ''))
      }
      this.guaranteeDepositTarget.value = guarantee_deposit_value;
      this.updateSearchCriteria('guarantee_deposit', guarantee_deposit_value);

      this.weeklyPaymentTarget.value = weekly_payment_value;
      this.updateSearchCriteria('new_payment', weekly_payment_value);

      this.periodsTarget.value = periods_value;
      this.updateSearchCriteria('periods', periods_value);

      this.transmissionTarget.value = tabSelected.dataset.tabTransmission;
      this.leasingProductIdTarget.value = tabSelected.dataset.tabLeasingProduct;
    }
  }

  filtersVehicleSectio(){
    const select_card = document.querySelectorAll('.select_card')
    const box_cotizacion_filter = document.querySelector('.box-cotizacion-filter')
    const tab_content = document.querySelector('.tab-content')
    const box_aplicar = document.querySelectorAll('.box-aplicar .btn.button')
    const modal_footer_btn = document.querySelector('.modal-footer .button.button-primary')
    select_card.forEach((item)=>{
      item.addEventListener('click', function (e) {
        box_cotizacion_filter.classList.toggle('active');
        tab_content.classList.toggle('active');
      });
    })
    box_aplicar.forEach((item)=>{
      item?.addEventListener('click', function (e) {
        box_cotizacion_filter.classList.toggle('active');
        tab_content.classList.toggle('active');
      });
    })
    if(modal_footer_btn) {
      modal_footer_btn.addEventListener('click', function (e) {
        box_cotizacion_filter.classList.toggle('active');
        tab_content.classList.toggle('active');
      });
    }
  }

  calculateActiveTab() {
    const tabs = this.element.querySelectorAll('.box-cotizacion-item > .tab-pane');
    for (const target of tabs) {
      if (target.dataset.tabLeasingProduct == this.element.dataset.preselectedLeasingProductId) {
        const priceLabel = target.querySelector('.price-label')
        const guaranteeDeposit = target.querySelector('.guarantee-deposit')
        priceLabel.textContent = numberToCurrency(this.element.dataset.preselectedWeeklyPayment)
        guaranteeDeposit.value = this.element.dataset.preselectedGuaranteeDeposit
        return target;
      }
    }
    return null;
  }

  activateLeasingProductModel() {
    const tab = this.calculateActiveTab();
    if (tab) {
      let currentModel = tab.dataset.tabModel;
      this.updateSearchCriteria('model', this.element.dataset.preselectedModel || currentModel);
      const models = this.element.querySelectorAll('.box-cotizacion-item > .tab-pane');
      models.forEach((model) => {
        model.classList.toggle('active', model.dataset.tabLeasingProduct === this.element.dataset.preselectedLeasingProductId);
      });
    }
  }

}
