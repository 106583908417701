import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['textArea']

  connect() {
    this.submitButton = document.getElementById('submit-button')
    this.containerElement = document.querySelector('.response-container')
    this.setUpTriggers()
  }

  setUpTriggers() {
    document.body.addEventListener('pld:document:loaded', event => {
      this.canSendForm()
    })
    document.body.addEventListener('pld:document:removed', event => {
      this.disableForm()
    })
    this.textAreaTarget.addEventListener('input', event => {
      if(this.textAreaTarget.classList.contains('invalid')) {
        this.textAreaTarget.classList.remove('invalid')
        this.containerElement.classList.remove('invalid')
      }

      this.canSendForm()
    })
    this.element.addEventListener('submit', event => { this.validateForm(event) })
    this.submitButton.addEventListener('click', event => { this.element.requestSubmit() })
  }

  canSendForm() {
    if(!this.validForm()) return

    this.submitButton.disabled = false
  }

  disableForm() {
    if(this.submitButton.disabled) return

    this.submitButton.disabled = true
  }

  validForm() {
    return this.documentsPending() && this.textAreaTarget.value !== ''
  }

  documentsPending() {
    return document.querySelectorAll('[data-report-document-status="false"]').length === 0
  }

  validateForm(event) {
    if(this.validForm()) {
      event.currentTarget.requestSubmit()
    } else {
      event.preventDefault()
      event.stopPropagation()
      this.renderErrors()
    }
  }

  renderErrors() {
    const pendingDocs = document.querySelectorAll('[data-report-document-status="false"]')
    if(this.textAreaTarget.value === '') {
      this.textAreaTarget.classList.add('invalid')
      this.containerElement.classList.add('invalid')
    }

    pendingDocs.forEach(element => {
      element.querySelector('.dropzone').classList.add('invalid')
    })
  }
}
