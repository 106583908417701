import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['statusField', 'descriptionArea', 'actionTakenArea', 'submitButton']

  connect() {
    this.element.addEventListener('submit', event => { this.submit(event) })
    this.statusFieldTargets.forEach(element => {
      element.addEventListener('change', event => { this.enableSubmit(event) })
    })
    this.descriptionAreaTarget.addEventListener('keyup', event => { this.enableSubmit(event) })
    this.actionTakenAreaTarget.addEventListener('keyup', event => { this.enableSubmit(event) })
  }

  submit(event) {
    if(this.validForm()) {
      this.element.requestSubmit()
    } else {
      event.preventDefault()
      event.stopPropagation()
      this.renderErrors()
    }
  }

  enableSubmit(event) {
    this.removeErrors()
    if(!this.validForm()) {
      this.submitButtonTarget.disabled = true
      return
    }

    this.submitButtonTarget.disabled = false
  }

  validForm() {
    return this.statusSelected() && this.validDescription() && this.validActionTaken()
  }

  statusSelected() {
    return this.statusFieldTargets.filter(option => { return option.checked }).length > 0
  }

  validDescription() {
    return this.descriptionAreaTarget.value !== ''
  }

  validActionTaken() {
    return this.actionTakenAreaTarget.value !== ''
  }

  renderErrors() {
    this.statusError()
    this.descriptionError()
    this.actionTakenError()
  }

  removeErrors() {
    if(this.statusSelected()) {
      this.statusFieldTargets.forEach(element => {
        const label = element.parentNode.querySelector('label')
        label.classList.remove('invalid')
      })
    }
    if(this.validDescription()) this.descriptionAreaTarget.classList.remove('invalid')
    if(this.validActionTaken()) this.actionTakenAreaTarget.classList.remove('invalid')
  }

  statusError() {
    if(this.statusSelected()) return

    this.statusFieldTargets.forEach(element => {
      const label = element.parentNode.querySelector('label')
      label.classList.add('invalid')
    })
  }

  descriptionError() {
    if(this.validDescription()) return

    this.descriptionAreaTarget.classList.add('invalid')
  }

  actionTakenError() {
    if(this.validActionTaken()) return

    this.actionTakenAreaTarget.classList.add('invalid')
  }
}
