import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['typeSelector', 'addOptionButton']

  connect() {
    this.notOptionableType = ['open', 'image', 'long_answer', 'geo_location']
    this.typeSelectorTarget.addEventListener('change', event => this.handleQuestionType())
  }

  handleQuestionType() {
    if(this.notOptionableType.indexOf(this.typeSelectorTarget.value) > -1) {
      if(!this.buttonTargetHidden()) this.addOptionButtonTarget.classList.add('d-none')
    } else {
      if(this.buttonTargetHidden()) this.addOptionButtonTarget.classList.remove('d-none')
    }
  }

  buttonTargetHidden() {
    return this.addOptionButtonTarget.classList.contains('d-none')
  }
}
