import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import Toast from '../utils/toast'

export default class extends Controller {
  static targets = ['loanApplicationId', 'bankAccount', 'contractDate', 'contractNumber', 'bankAccountReference', 'vinNumber',
                    'licencePlate', 'driverLicense', 'motorNumber', 'carInvoiceNumber', 'carProvider', 'insurancePolicyNumber',
                    'kilometers', 'exteriorColor', 'interiorColor']

  connect(){
    if(this.vinNumberTarget.value !== ''){
      this.updateCarField(this.vinNumberTarget.value)
    }
  }

  changeBankAccount(e){
    this.generateContractData()
  }

  changeContractDate(e){
    this.generateContractData()
  }

  async changeVinNumber(e){
    if(e.currentTarget.value !== ''){
      await this.updateCarField(e.currentTarget.value)
    }
  }

  async updateCarField(vin_number){
    const carData = await this.getDataFromFleetManager(vin_number)
    this.updateInstrumentationFields(carData)
  }

  async generateContractData(){
    if(this.are_all_fields_set()){
      const contractData = await this.getContractData()
      this.contractNumberTarget.value = contractData.contract_number
      this.bankAccountReferenceTarget.value = contractData.bank_account_reference
    }
  }

  
  async getContractData(){
    const response = await get('/admin/instrumentation_documents/bank_reference_info',{
      query: {
        loan_application_id: this.loanApplicationIdTarget.value,
        contract_date: this.contractDateTarget.value,
        bank_account_id: this.bankAccountTarget.value
      }
    })
    if (response.ok) {
      return await response.json
    }
  }

  async getDataFromFleetManager(vin_number){
    const response = await get('/admin/instrumentation_documents/fleet_manager_info',{
      query: { vin_number: vin_number }
    })
    if(response.ok){
      return await response.json
    }
  }

  updateInstrumentationFields(carData){
    if(carData.hasOwnProperty('error')){
      const errorMessage = new Toast('No se encontró información del vehículo', true)
      errorMessage.show()
      this.licencePlateTarget.value = ''
      this.driverLicenseTarget.value = ''
      this.motorNumberTarget.value = ''
      this.carInvoiceNumberTarget.value = ''
      this.carProviderTarget.value = ''
      this.insurancePolicyNumberTarget.value = ''
      this.kilometersTarget.value = ''
      this.exteriorColorTarget.value = ''
      this.interiorColorTarget.value = ''
    }else{
      this.licencePlateTarget.value = carData.license_plate
      this.driverLicenseTarget.value = carData.licence_driver_number
      this.motorNumberTarget.value = carData.motor_number
      this.carInvoiceNumberTarget.value = carData.car_invoice_number
      this.carProviderTarget.value = carData.car_provider
      this.insurancePolicyNumberTarget.value = carData.insurance_policy_number
      this.kilometersTarget.value = carData.kilometers
      this.exteriorColorTarget.value = carData.color_exterior
      this.interiorColorTarget.value = carData.color_interior
    }
  }

  are_all_fields_set(){
    if(this.loanApplicationIdTarget.value !== '' && this.bankAccountTarget.value !== '' && this.contractDateTarget.value !== ''){
      return true
    }
    return false
  }
}