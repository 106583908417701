import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    address: String
  }

  connect() {
    this.element.addEventListener('click', event => {
      if(this.addressValue)
        navigator.clipboard.writeText(this.addressValue)
    })
  }
}
