import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.transitionTime = 500
    this.forms = Array.from(document.querySelectorAll('.application-form'))
    this.mainContainer = document.querySelector('.steps-container')
    this.bindForms()
  }

  bindForms() {
    this.forms.forEach((el) => {
      el.addEventListener('submit', (event) => {
        const formTarget = event.target
        const nodeTarget = formTarget.parentNode
        this.animate(event, nodeTarget)

        const transition = setInterval(() => {
          el.requestSubmit()
          clearInterval(transition)
        }, this.transitionTime)
      })
    })
  }

  animate(event, target) {
    if(this.hasAnimation(target)) return

    target.classList.remove('car-slide-in')
    this.mainContainer.classList.remove('cars-open')
    event.preventDefault()
    event.stopPropagation()
    target.classList.add('car-slide-out')
    this.mainContainer.classList.add('application-open')
  }

  hasAnimation(target) {
    return target.classList.contains('car-slide-out')
  }
}
