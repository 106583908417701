export default class {
  constructor(num) {
    // Removed currency format from original and the usage of decials (cents)
    this.integers = Math.floor(num)
  }

  numberToWords() {
    if (this.integers === 0)
      return "cero"

    return this.millions(this.integers)
  }

  units(num) {
    const unidadesMap = {
      1: "uno",
      2: "dos",
      3: "tres",
      4: "cuatro",
      5: "cinco",
      6: "seis",
      7: "siete",
      8: "ocho",
      9: "nueve"
    }

    return unidadesMap[num] || ""
  }

  tens(num) {
    const tensMap = {
      1: {
        0: "diez",
        1: "once",
        2: "doce",
        3: "trece",
        4: "catorce",
        5: "quince",
        _default: n => { return n > 5 ? `dieci${this.units(n)}` : '' }
      },
      2: {
        0: "veinte",
        _default: n => { return n > 0 ? `veinti${this.units(n)}` : '' }
      },
      3: "treinta",
      4: "cuarenta",
      5: "cincuenta",
      6: "sesenta",
      7: "setenta",
      8: "ochenta",
      9: "noventa",
      0: n => this.units(n)
    }

    const ten = Math.floor(num / 10)
    const unit = num - (ten * 10)

    if (tensMap[ten]) {
      const value = tensMap[ten]
      if (typeof value === "object") {
        return value[unit] || value._default(unit)
      }else if(typeof value === 'function') {
        return value(unit)
      } else {
        return ten > 2 ? this.tensAnd(value, unit) : value
      }
    }

    return ""
  }

  tensAnd(strSin, numUnits) {
    if (numUnits > 1) return `${strSin} y ${this.units(numUnits)}`

    if(numUnits === 1) return `${strSin} y un`

    return strSin
  }

  hundreds(num) {
    const hundredsMap = {
      1: (n) => { return n > 0 ? `ciento ${this.tens(n)}` : 'cien' },
      2: "doscientos",
      3: "trescientos",
      4: "cuatrocientos",
      5: "quinientos",
      6: "seiscientos",
      7: "setecientos",
      8: "ochocientos",
      9: "novecientos",
      _default: n => this.tens(n)
    }
    const hundreds = Math.floor(num / 100)
    const tens = num - (hundreds * 100)

    if (hundredsMap[hundreds]) {
      const value = hundredsMap[hundreds]
      if (typeof value === "function") {
        return value(tens)
      } else {
        return `${value} ${this.tens(tens)}`
      }
    }
    return hundredsMap._default(tens)
  }

  section(num, divider, strSingular, strPlural) {
    let hundreds = Math.floor(num / divider)
    let remainder = num - (hundreds * divider)

    let letters = ""

    if (hundreds > 0) {
      if (hundreds > 1)
        letters = `${this.hundreds(hundreds)} ${strPlural}`
      else
        letters = strSingular
    }

    if (remainder > 0)
      letters += ""

    return letters
  }

  thousands(num) {
    const divider = 1000;
    const hundreds = Math.floor(num / divider)
    const remainder = num - (hundreds * divider)
    const strThousands = this.section(num, divider, "mil", "mil")
    const strHundreds = this.hundreds(remainder)

    if (strThousands === "") return strHundreds

    return strThousands + " " + strHundreds
  }

  millions(num) {
    const divider = 1000000;
    const hundreds = Math.floor(num / divider)
    const remainder = num - (hundreds * divider)

    const strMillions = this.section(num, divider, "un millon de", "millones");
    const strThousands = this.thousands(remainder);

    if (strMillions === "") return strThousands

    return `${strMillions} ${strThousands}`
  }
}
