import { Controller } from "stimulus"

export class FormValidationController extends Controller {
  disconnect() {
    this.validatableInputs.forEach((input) => input.removeEventListener(this.eventForInputType[`${input.type}`], this.validate))
    this.element.removeEventListener('submit', this.validateAll)
  }

  bindForm() {
    this.element.addEventListener('submit', this.validateAll.bind(this))
  }

  bindInputs() {
    let inputs = this.element.querySelectorAll('input')
    inputs.forEach((input) => {
      let input_name = input.getAttribute('id')
      if (input_name) {
        input_name = input_name.replace(/_true/g, '').replace(/_false/g, '')
                               .replace(/_female/g, '').replace(/_male/g, '')
        let validate = this.validation.validatable(input_name.replace(/_[0-9]$/g,''))
        if (validate) {
          input.addEventListener(this.eventForInputType[`${input.type}`], this.validate.bind(this))
          this.validatableInputs.push(input)
        }
      }
    })
  }

  bindSelects() {
    let selects = this.element.querySelectorAll('select')
    selects.forEach((select) => {
      let input_name = select.getAttribute('id')
      if(input_name) {
        let validate = this.validation.validatable(input_name.replace(/_[0-9]$/g,''))
        if(validate) {
          select.addEventListener(this.eventForInputType['select'], this.validate.bind(this))
          this.validatableInputs.push(select)
        }
      }
    })
  }

  inputValue(input) {
    if(input.type === 'radio' || input.type === 'checkbox'){
      return this.handleCheckeable(input) ? input.value : ''
    } else {
      return input.value
    }
  }

  handleCheckeable(input) {
    const nodes = Array.from(input.closest('fieldset').querySelectorAll(`input[type=${input.type}]`))
    const checked = nodes.some(n => n.checked)
    return checked
  }

  validForm() {
    let valid = Object.values(this.errors).some((value) => value)
    return !valid
  }

  validateAll(event) {
    this.validatableInputs.forEach((input) => this.validate({ target: input }))
    if (!this.validForm()) {
      event.preventDefault()
      event.stopPropagation()

      let submit = event.target.querySelector('[type="submit"]')
      if (submit) {
        const firstInvalid = document.querySelector('select.invalid, input.invalid')
        // scrollIntoView does not work with inputs fields directly
        firstInvalid.parentNode.scrollIntoView(false)
        submit.disabled = false
      }
    }
  }

  validate(event) {
    let inputValue = this.inputValue(event.target)
    let inputId = event.target.getAttribute('id').replace(/_[0-9]$/g, '')
                       .replace(/_true/g, '').replace(/_false/g, '')
                       .replace(/_female/g, '').replace(/_male/g, '')
    let validationResult = this.validation.validate(inputValue, inputId, this.newRecord)

    let validator = validationResult.find((validator) => !validator.result.valid)
    this.errors[inputId] = !!validator
    this.render(event.target, validationResult)

    if(typeof(validator) === 'undefined') {
      validationResult = this.validation.validate(inputValue, inputId.replace('prospect', 'user'), this.newRecord)
      validator = validationResult.find((validator) => !validator.result.valid)
      this.errors[inputId] = !!validator
      this.render(event.target, validationResult)
    }
  }

  render(target, validation) {
    console.log('Implement me!')
  }
}
