import { Controller } from "@hotwired/stimulus"
import Dropzone from "../utils/dropzone"

export default class extends Controller {
  connect(){
    this.dropzone = new Dropzone(this.element)
    this.dropzoneInput = this.element.querySelector('.dropzone-input');
    this.form = document.getElementById(this.element.dataset.formId)
    this.inputFiles = [];

    this.dropzoneInput.addEventListener('input', event => {
      event.preventDefault()
      event.stopPropagation()
      this.addAttachments(event.currentTarget.files)
      this.checkAttributes()
      this.sendForm()
    }, false)

    // override dropActionEvent
    const defaultDropAction = this.dropzone.element.ondrop
    this.dropzone.element.ondrop = (e) => {
      if (typeof defaultDropAction === 'function') {
        defaultDropAction(e);
      }
      this.addAttachments(e.dataTransfer.files)
      this.sendForm()
    }
  }

  addAttachments(newFiles) {
    const list = new DataTransfer()
    const files = Array.from(newFiles)
    const isMultiple = this.dropzoneInput.getAttribute('multiple')

    if (this.inputFiles.length > 0 && isMultiple) {
      for (const file of files) list.items.add(file)
      for (const file of this.inputFiles) list.items.add(file)
      this.dropzoneInput.files = list.files
    } else {
      this.dropzoneInput.files = newFiles
    }

    this.inputFiles = Array.from(this.dropzoneInput.files)
  }

  checkAttributes() {
    if (this.dropzoneInput.getAttribute('hide')){
      this.element.classList.add('d-none')
    }
    if (this.dropzoneInput.getAttribute('success')){
      this.element.classList.add('upload-success')
    }
  }

  sendForm() {
    this.form.requestSubmit()
  }
}
