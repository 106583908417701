import NumberToWords from '../utils/numbers_to_words'

export function promptForSelect(message = "Selecciona una opción") {
  const prompt = document.createElement('option')
  prompt.disabled = true
  prompt.selected = true
  prompt.value = ''
  prompt.text = message
  return prompt
}

// helper function for NumbersToWords class
export function numberToWords(num) {
  return new NumberToWords(num).numberToWords();
}

export function dataURLtoBlob (dataURL) {
  // Decode the dataURL
  var binary = atob(dataURL.split(',')[1])
  // Create 8-bit unsigned array
  var array = []
  for(var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i))
  }
  // Return our Blob object
  return new Blob([new Uint8Array(array)], {type: 'image/png'})
}

export function updateClassOnElements(elements, className, action) {
  elements.forEach(element => {
    switch(action) {
      case 'add':
        element.classList.add(className)
        break
      case 'remove':
        element.classList.remove(className)
        break
      default:
        console.log('Error: invalid action to perform!')
        break
    }
  })
}

export function inputCreate(config) {
  const input = document.createElement('input')
  input.type = config.type
  input.name = config.name
  input.id = config.id
  input.value = config.value
  if(config.classes?.length > 0) config.classes.forEach(c => { input.classList.add(c) })
  return input
}

export function truncateString(str, len) {
  if(str.length <= len) return str

  return `${str.slice(0, len)}...`
}

export function newElement(tag, params) {
  const element = document.createElement(tag)
  if (params) {
    for (const key in params) {
      const value = params[key]

      switch (key) {
        case 'class':
          if (Array.isArray(value)) {
            value.forEach(className => className && element.classList.add(className))
          }
          if (value !== '') element.classList.add(value)
          break;
        case 'style':
          for (const styleKey in value) {
            element.style[styleKey] = value[styleKey];
          }
          break;
        case 'text':
          element.innerText = value || '&nbsp;'
          break
        case 'id':
          element.id = value
        default:
          element[key] = value
          break
      }
    }
  }
  return element
}

export function numberToCurrency(numberString){
  const currencyFormat =  { style: 'currency', currency: 'USD', minimumSignificantDigits: 1 }
  const formatedNumber = parseFloat(numberString).toLocaleString('en-US', currencyFormat)
  return formatedNumber
}

window.isMobile = function () {
 if ( navigator.userAgent.match(/Android/i)
 || navigator.userAgent.match(/webOS/i)
 || navigator.userAgent.match(/iPhone/i)
 || navigator.userAgent.match(/iPad/i)
 || navigator.userAgent.match(/iPod/i)
 || navigator.userAgent.match(/BlackBerry/i)
 || navigator.userAgent.match(/Windows Phone/i) ) {
    return true
  } else {
    return false
  }
}
