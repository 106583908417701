import CarsSelectionController from './cars_selection_controller'
import Toast from '../utils/toast'
import PricingLocator from  '../utils/pricing_locator'
import { Modal } from 'bootstrap'

export default class LoanApplicationsCarsTabController extends CarsSelectionController {
  connect() {
    this.applyButton = document.getElementById('tab-apply-button')
    this.selectionModal = new Modal(document.getElementById('car-selection-modal'))
    this.setUpActions()
    const guaranteeDepositFields = document.querySelectorAll('.guarantee-deposit')
    const pricingLocator = new PricingLocator(guaranteeDepositFields,
                                              this.element, this.application)
  }

  setUpActions() {
    this.applyButton.addEventListener('click', event => {
      event.preventDefault()
      this.sendChangeNotification()
      this.selectionModal.hide()
    })
  }
  
  sendChangeNotification() {
    const customEvent = new CustomEvent('leasing-product-tab:changed', {
      bubbles: true,
      detail: this.searchCriteria,
  });
    document.body.dispatchEvent(customEvent)
  }
}
