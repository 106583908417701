import CarsSelectionController from './cars_selection_controller'
import PricingLocator from  '../utils/pricing_locator'
import { Modal } from 'bootstrap'

export default class UsersCarsTabController extends CarsSelectionController {
  connect() {
    const guaranteeDepositFields = document.querySelectorAll('.guarantee-deposit')
    const pricingLocator = new PricingLocator(guaranteeDepositFields,
                                              this.element, this.application)
  }
}
