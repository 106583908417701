import BaseValidator from "./base_validator"

export default class LengthValidator extends BaseValidator {
  validate() {
    let valid = true
    let errorMessage = ''

    if(this.options['is'] && this.value.length !== parseInt(this.options['is'])){
      valid = false
      errorMessage = this.errorMessages['wrong_length']['other'].replace('%{count}', this.options['is'])
    }else if(this.options['minimum'] && this.value.length < parseInt(this.options['minimum'])) {
      valid = false
      errorMessage = this.errorMessages['wrong_length']['one'].replace('%{count}', this.options['minimum'])
    }else if(this.options['maximum'] && this.value.length > parseInt(this.options['maximum'])) {
      valid = false
      errorMessage = this.errorMessages['wrong_length']['other'].replace('%{count}', this.options['maximum'])
    }else if(this.options['minimum'] && this.value.length < parseInt(this.options['minimum']) &&
             this.value.length > parseInt(this.options['maximum'])) {
      valid = false
      errorMessage = this.errorMessages['wrong_length']['other'].replace('%{count}', this.options['minimum'])
    }
    return { valid: valid, message: errorMessage }
  }
}
