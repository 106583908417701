import { Controller } from "@hotwired/stimulus"
 import { Tab } from 'bootstrap'

export default class extends Controller {

  connect() {
    this.changeDetected = false;
    this.tabList = this.element.querySelectorAll('button')
    this.formTarget = document.getElementById('guarantee-deposits-form')
    this.bindButtons()
    this.bindForm()
    document.body.addEventListener('form:changed', event => {
      this.changeDetected = true
    })
  }

  bindButtons() {
    this.tabList.forEach(trigger => {
      const tabElement = new Tab(trigger)

      trigger.addEventListener('click', event => {
        if(this.changeDetected) {
          if (!confirm('¿Estás seguro de que quieres salir sin guardar los cambios realizados?')) {
            event.preventDefault()
          } else {
            tabElement.show()
            this.changeDetected = false;
          }
        } else {
          tabElement.show()
        }
      })
    })
  }

  bindForm() {
    if(this.formTarget === null) return
    const inputs = this.formTarget.querySelectorAll('input')
    inputs.forEach(el => {
      el.addEventListener('change', event => {
        this.changeDetected = true
      })
    })
  }
}
