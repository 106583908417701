import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.sent = false
    this.bindCodeFields()
  }

  bindCodeFields() {
    const fields = this.pinFields()
    this.setNumberRestriction(fields)
    this.setFocusEvent(fields)
    this.styleHandle(fields)
  }

  setNumberRestriction(fields) {
    fields.forEach(field => {
      field.addEventListener('input', (event) => {
        const target = event.currentTarget
        target.value = target.value.replace(/[^0-9]/g,'')
      })
      field.addEventListener('blur', (event) => {
        event.currentTarget.classList.remove('valid')
      })
    })
  }

  setFocusEvent(fields) {
    fields.forEach(field => {
      field.addEventListener('keyup', (event) => {
        const target = event.currentTarget
        const elements = fields
        if(event.keyCode == 8 && target.name !== 'digit_1') {
          target.classList.remove('valid')
          elements[target.name.replace('digit_', '') - 2].focus()
        }
        if(target.value.length === target.maxLength) {
          if(target === elements[elements.length - 1]) {
            this.prepareFormForSubmit()
          } else {
            target.classList.remove('valid')
            elements[target.name.replace('digit_', '')].focus()
          }
        }
      })
    })
  }

  styleHandle(fields) {
    fields.forEach(field => {
      field.addEventListener('focus', (event) => {
        const target = event.currentTarget
        target.value = ''
        target.classList.remove('invalid')
        target.classList.add('valid')
      })
    })
  }

  validateForm(){
    const unfillElement = this.pinFields().find(field => field.value.length == 0)
    if(unfillElement === undefined){
      this.prepareFormForSubmit()
    }
  }

  prepareFormForSubmit(){
    const verificationCodeField = document.getElementById('verification_code')
    const verificationCode = this.pinFields().map(e => e.value).join('')
    verificationCodeField.value = verificationCode
    this.submitForm()
  }

  pinFields(){
    return [...this.element.querySelectorAll('.code-field')]
  }

  submitForm() {
    if(!this.sent) {
      this.sent = true
      this.element.requestSubmit()
    }
  }
}
