export const events = {
  checkbox: 'change',
  date: 'change',
  email: 'blur',
  number: 'blur',
  password: 'blur',
  radio: 'change',
  tel: 'blur',
  text: 'blur',
  url: 'blur',
  select: 'change'
}
