import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ['dictumStatus', 'rejectedSection', 'rejectedReason', 'vinNumber', 'bankReference', 'contractDate']

  async connect() {
    this.dictumStatusTarget.addEventListener("change", (event) => {
      this.showRejectedSection(event.currentTarget.value)
    })
    this.generateBankReference()
  }

  showRejectedSection(dictumStatus){
    if(dictumStatus == 'rejected'){
      this.rejectedSectionTarget.classList.remove("d-none")
      this.rejectedReasonTarget.setAttribute("required", "true")
      this.vinNumberTarget.removeAttribute("required")
    }else{
      this.rejectedSectionTarget.classList.add("d-none")
      this.rejectedReasonTarget.removeAttribute("required")
      this.vinNumberTarget.setAttribute("required", "true")
    }
  }

  async generateBankReference(){
    const response = await this.getBankReference()
    this.bankReferenceTarget.value = response.bank_reference
  }

  async getBankReference(){
    const response = await get(`/admin/loan_applications/${this.bankReferenceTarget.dataset.loanApplicationId}/analysis_dictums/bank_reference?contract_date=${this.contractDateTarget.value}`)
    if (response.ok) {
      return await response.json
    }
  }
}
