// El objetivo de extraer este codigo del onbording controller es con el de que el componente
// sea mas reutilizable. ya que dicho controller esta muy acoplado al comportamiento del onboarding
// por lo que en este se busca que el coportamiento sea mas generico

import { Controller } from "@hotwired/stimulus"
import PaginableForm from "./shared/forms/paginable_form"
import QuestionnaireValidator from "./shared/forms/questionnaire_validator"
import ErrorsNotifier from "./shared/forms/errors_notifier"

export default class extends Controller {
  static targets = ['prevButton', 'nextButton', 'submitButton']
  connect() {
    this.paginableForm = new PaginableForm(this.element)
    this.validator = new QuestionnaireValidator()
    this.#updateButtonsState()
  }

  showNext(_event) {
    const errors = this.validator.validateSection(this.paginableForm.getCurrentPageElement())
    if (errors.length > 0) {
      this.#showErrors(errors)
    } else {
      this.paginableForm.updatePage(1)
      this.#updateButtonsState()
    }
  }

  showPrev(_event) {
    this.paginableForm.updatePage(-1)
    this.#updateButtonsState()
  }

  submitForm(_event) {
    const errors = this.validator.validateSection(this.paginableForm.getCurrentPageElement())
    if (errors.length > 0) {
      this.#showErrors(errors)
    } else {
      this.submitButton.setAttribute('disabled', true)
      this.prevButton.setAttribute('disabled', true)
      this.element.submit()
    }
  }

  #showErrors(errors) {
    errors.forEach(error => {
      new ErrorsNotifier(error[0], error[1]).render()
    })
  }

  #updateButtonsState() {
    if (this.paginableForm.isFirstPage()) {
      this.prevButton.setAttribute('disabled', true)
    } else {
      this.prevButton.removeAttribute('disabled')
    }
    if (this.paginableForm.isLastPage()) {
      if(!this.nextButton.classList.contains('d-none')) this.nextButton.classList.add('d-none')
      if(this.submitButton.classList.contains('d-none')) this.submitButton.classList.remove('d-none')
    } else {
      if(this.nextButton.classList.contains('d-none')) this.nextButton.classList.remove('d-none')
      if(!this.submitButton.classList.contains('d-none')) this.submitButton.classList.add('d-none')
    }
  }

  get prevButton() {
    return this.prevButtonTarget
  }

  get nextButton() {
    return this.nextButtonTarget
  }

  get submitButton() {
    return this.submitButtonTarget
  }
}
