import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['firstSegment', 'secondSegment', 'rfcValue']

  connect() {
    this.firstSegmentVal = ''
    this.secondSegmentVal = ''
  }

  validateFirstSegment(e) {
    let maxLenght = 10
    this.firstSegmentVal = this.sanitize(e.currentTarget.value, maxLenght)
    e.currentTarget.value = this.firstSegmentVal
    this.updateRfcVal()

    if (this.maxLength(this.firstSegmentVal, maxLenght)) {
      this.secondSegmentTarget.focus()
      this.firstSegmentTarget.value = this.firstSegmentVal
    }
  }

  validateSecondSegment(e) {
    let maxLenght = 3
    this.secondSegmentVal = this.sanitize(e.currentTarget.value, maxLenght)
    e.currentTarget.value = this.secondSegmentVal
    this.updateRfcVal()

    if (this.secondSegmentVal.length <= 0 && this.isDeleting(e) )
      this.firstSegmentTarget.focus()

    if (this.maxLength(this.secondSegmentVal, maxLenght))
      this.secondSegmentTarget.value = this.secondSegmentVal
  }

  sanitize(val, length) {
    val = val.replace(/[^A-Za-z0-9]/g,'').substring(0, length)
    return val
  }

  updateRfcVal() {
    let newVal = `${this.firstSegmentVal}${this.secondSegmentVal}`
    this.rfcValueTarget.value = newVal
  }

  maxLength(value, length) {
    return value.length >= length
  }

  isDeleting(event) {
    return event.inputType === 'deleteContentBackward'
  }
}
