import { Controller } from "@hotwired/stimulus"

export default class QuotationController extends Controller {
  connect() {
    let btn_ver_cotizacion = document.querySelector('.btn-ver-cotizacion');
    let cotizacion_content_items = document.querySelector('.cotizacion-content-items');
    let cotizacion_toggle_box = document.querySelector('.cotizacion-toggle-box');
    let btn_ocultar_cotizacion = document.querySelector('.btn-ocultar-cotizacion');
    let cotizacion_content_box  = document.querySelector('.cotizacion-content-box');
    btn_ver_cotizacion.addEventListener('click',function(){
      let total_height = cotizacion_toggle_box.clientHeight;
      cotizacion_content_items.style.height = total_height+'px';
      btn_ver_cotizacion.style.height = '0';
      btn_ver_cotizacion.style.opacity = '0';
      cotizacion_content_box.classList.add('active')
    })
    btn_ocultar_cotizacion.addEventListener('click',function(){
      cotizacion_content_items.style.height = '0px';
      btn_ver_cotizacion.style.height = '24px';
      btn_ver_cotizacion.style.opacity = '1';
      cotizacion_content_box.classList.remove('active')
    })
  }
}