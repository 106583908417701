import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = ['form', 'password']

  async validateForm(event){
    const response = await this.validate_password(this.passwordTarget.value)
    if(!response.valid){
      event.preventDefault()
      event.stopPropagation()
    }
  }

  async validate_password(password){
    const response = await post('/credit_bureau_query_authorizations/validate', {
      body: JSON.stringify({ password: password })
    })
    if (response.ok) {
      return await response.json
    }
  }
}