import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'

// Connects to data-controller="modal"
export default class extends Controller {
  static values = {
    show: String
  }

  connect() {
    this.modal = new Modal(this.element, {
      keyboard: false
    })

    if (this.showValue != "false"){
      this.modal.show()
    }
  }

  disconnect() {
    this.modal.hide()
  }
}