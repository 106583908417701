import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {

  connect() {
      this.element.addEventListener('broadcast', (e) => {
          this.get_data(e)
      })
  }

  get_data(e){
    e.preventDefault()
    e.stopPropagation()

    let params = new URLSearchParams()
    const qQuotationsElement = document.getElementById("q_quotations")


    if(qQuotationsElement) params.append('q_quotations', qQuotationsElement.value)
    params.append('q',document.getElementById("q").value)
    params.append('status',document.getElementById("status").value)
    params.append('product',document.getElementById("product").value)

    params.append('q_rev',document.getElementById("q_rev").value)
    params.append('status_rev',document.getElementById("status_rev").value)
    params.append('product_rev',document.getElementById("product_rev").value)

    params.append('active_section',this.element.querySelector("#active_section").value)

    get(`${window.location.href.split('?')[0]}?${params}`,{
      responseKind: "turbo-stream"
    })
  }
}
