import { Controller } from "stimulus"
import { Modal } from 'bootstrap'

export default class NotificationController extends Controller {

  connect() {
    this.modal = new Modal(document.getElementById('logoutModal'))
    this.modal.show()
  }
}
