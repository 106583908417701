// app/javascript/controllers/select-link_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const code = this.element.dataset.selectLinkCode;
    const select = this.element;
    
    select.addEventListener("change", (event) => {
      const selectedOption = select.options[select.selectedIndex];
      const url = selectedOption.getAttribute("data-url");
      
      if (url) {
        window.location.href = url;
      }
    });

    if (code) {
      Array.from(select.options).forEach((option) => {
        if (option.value === code) {
          option.selected = true;
        } else {
          option.selected = false;
        }
      });
    }
  }
}
