import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.inputTarget = document.querySelector('#input-target')
    this.inputTarget.addEventListener('keyup', () => { this.updateHiddenValue() })
    this.hiddenTarget = this.element.querySelector('input[type=checkbox]')
    this.hiddenDefaultValue = this.hiddenTarget.value
  }

  showTarget(event) {
    if(this.inputTarget.classList.contains('d-none')) {
      this.inputTarget.classList.remove('d-none')
      this.inputTarget.focus()
    } else {
      this.inputTarget.classList.add('d-none')
      this.hiddenTarget.value = this.hiddenDefaultValue
      this.inputTarget.value = ''
    }
  }

  updateHiddenValue() {
    this.hiddenTarget.value = `${this.hiddenDefaultValue} (${this.inputTarget.value})`
  }
}
