import { Controller } from "@hotwired/stimulus"
import ButtonManager from "./utils/button_manager"
import PreviewManager from "./utils/preview_manager"

export default class extends Controller {
  connect() {
    this.controlsContainer = this.element.querySelector('.controls')
    this.buttonManager = new ButtonManager(this.element, this.controlsContainer)
    this.previewManager = new PreviewManager(this.element, '.preview')
    this.#bindButtonEvents()
    this.#renderButtons()
    this.element.addEventListener('image:content-change', _event => { this.#updateButtons() })
  }

  #renderButtons() {
    let actionsToAppend = this.previewManager.isCompleted() ? this.#loadedImageActions : this.#emptyImageActions
    this.buttonManager.renderButtons(actionsToAppend)
  }

  #updateButtons() {
    const actionsToRemove = this.previewManager.isCompleted() ? this.#emptyImageActions : this.#loadedImageActions
    this.buttonManager.removeButtons(actionsToRemove)
    this.#renderButtons()
  }

  #bindButtonEvents() {
    this.#bindActionsToPerform()
    this.buttonManager.bindButtonActions(this.performableActions, this.#eventActions())
  }

  #bindActionsToPerform() {
    this.performableActions = []
    if(this.#isPreviewable) this.performableActions.push('previewable')
    if(this.#isCapturable) this.performableActions.push('capturable')
    if(this.#isLoadable) this.performableActions.push('loadable')
    if(this.#isEraseable) this.performableActions.push('eraseable')
  }

  #eventActions() {
    return {
      capturable: [['click', this.uploadClickCallBack, this.element]],
      loadable: [['click', this.uploadClickCallBack, this.element]],
      previewable: [['click', this.previewClickCallBack, this.element]],
      eraseable: [['click', this.deleteClickCallback, this.element]]
    }
  }

  uploadClickCallBack(event, element) {
    event.preventDefault()
    const input = element.querySelector('input[type="file"]')
    if(event.currentTarget.dataset.buttonAction === 'capturable' && input) {
      input.setAttribute('capture', 'camera')
    } else {
      input.removeAttribute('capture', 'camera')
    }

    if(input) input.click()
  }

  previewClickCallBack(event, element) {
    event.preventDefault()
    const preview = element.querySelector('.preview')
    window.open(preview.dataset.fileUrl, '_blank')
  }

  deleteClickCallback(event, element) {
    event.preventDefault()
    if(element.dataset.filePersisted === 'true') {
      // TODO: add logic to remove persisted images
    } else {
      element.dispatchEvent(new Event('image:content-removed'))
      element.dispatchEvent(new Event('image:content-change'))
    }
  }

  get #emptyImageActions() {
    const emptyActions = []
    if(this.#isCapturable) emptyActions.push('capturable')
    if(this.#isLoadable) emptyActions.push('loadable')
    return emptyActions
  }

  get #loadedImageActions() {
    const loadedActions = []
    if(this.#isPreviewable) loadedActions.push('previewable')
    if(this.#isEraseable) loadedActions.push('eraseable')
    return loadedActions
  }


  get #isPreviewable() {
    return this.element.dataset.elementPreviewable == 'true'
  }

  get #isCapturable() {
    return this.element.dataset.elementCapturable == 'true'
  }

  get #isLoadable() {
    return this.element.dataset.elementLoadable == 'true'
  }

  get #isEraseable() {
    return this.element.dataset.elementEraseable == 'true'
  }
}
