import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    options: { type: Object, default: { threshold: [1] } }
  }

  connect() {
    this.pinTarget = document.getElementById('steps_header')
    const observer = new IntersectionObserver(([e]) => this.toggleBar(e),
                                              this.optionsValue)

    observer.observe(this.element);
  }

  toggleBar(e) {
    this.pinTarget.classList.toggle('is-pinned', e.intersectionRatio < 1)
  }
}
