import { Validation } from "../validators"
import { FormValidationController } from "./form_validation_controller"
import { events } from "../utils/events"
export default class OnboardingController extends FormValidationController {
  static targets = ["form"]
  static values = {
    submitMessage: { type: String, default: 'Enviar información' }
  }

  connect() {
    this.companyLabels = {
      didi: 'DiDi',
      uber: 'Uber'
    }
    this.newRecord = this.formTarget.dataset.newrecord
    this.eventForInputType = events
    this.validation = new Validation()
    this.validatableInputs = []
    this.errors = {}
    this.bindInputs()
    this.bindSelects()
    this.bindForm()

    this.currentTab = 0
    this.showTab(this.currentTab)
    this.validableUberClassification()
  }

  showTab(n) {
    const tabs = document.getElementsByClassName("tab")
    tabs[n].style.display = "block"
    this.updateProgressIndicator(n)
    document.getElementById("prevBtn").style.display = n == 0 ? "none" : "inline"
    const nextButton = document.getElementById("nextBtn")
    nextButton.querySelector('.label').innerHTML = n == (tabs.length - 1) ? this.submitMessageValue : "Siguiente"
    if (n == tabs.length -1) {
      const icon = nextButton.querySelector('.arrow')
      icon.classList.remove('arrow')
      icon.classList.add('check')
    } else {
      const icon = nextButton.querySelector('.check')
      if(icon) {
        icon.classList.remove('check')
        icon.classList.add('arrow')
      }
    }
  }

  updateProgressIndicator(n) {
    const indicators = document.querySelectorAll('.onboarding__progress .indicator')
    indicators.forEach( (indicator, index) => {
      if(index <= n) {
        indicator.classList.add('active')
      } else {
        indicator.classList.remove('active')
      }
    })
  }

  showNext() {
    this.nextPrev(1)
  }

  showPrev() {
    this.nextPrev(-1)
    this.errors = {}
  }

  nextPrev(n) {
    const tabs = document.getElementsByClassName("tab");

    if (n > 0 && !this.validateForm(this.currentTab)) return false;
    tabs[this.currentTab].style.display = "none"
    this.currentTab = this.currentTab + n

    if (this.currentTab == 3){
      document.getElementsByClassName("cotizacion-content")[0]?.classList.add('d-none')
    }
    else if (this.currentTab < 3) {
      document.getElementsByClassName("cotizacion-content")[0]?.classList.remove('d-none')
    }
    
    if (this.currentTab >= tabs.length) {
      tabs[this.currentTab - 1].style.display = "block"
      this.submitForm()
      return false;
    }
    window.scrollTo(0, 1)
    this.showTab(this.currentTab)
  }

  enableSubmit() {
    let nextButton = document.getElementById('nextBtn')
    if(nextButton.disabled) nextButton.disabled = false
  }

  validateForm(index) {
    const formSection = document.getElementsByClassName("tab")[index]
    const inputs = Array.from(formSection.getElementsByTagName("input"))
    const selectors = Array.from(formSection.getElementsByTagName("select"))
    this.validateSectionFields(inputs)
    this.validateSectionFields(selectors)
    return this.validForm()
  }

  validateSectionFields(fields) {
    if(fields.length === 0)
      return true
    fields.forEach(field => {
      this.validatableInputs.forEach(input => {
        if(input.getAttribute('id').replace(/_[0-9]i/g,'') == field.getAttribute('id').replace(/_[0-9]i/g,'')) {
          this.validate({ target: field })
        }
      })
    })
  }

  submitForm() {
    this.formTarget.requestSubmit()
  }

  setCompany(e){
    const company = e.target.value
    const companyHolders = document.querySelectorAll('.company-holder')
    this.showUberClassificationField(company)
    companyHolders.forEach(ch => ch.innerHTML = company)
  }

  showUberClassificationField(company) {
    const targetFields = document.getElementById('uber-classification-fields')
    if(company === 'didi'){
      targetFields.classList.add('d-none')
      this.validatableInputs = this.validatableInputs.filter(v => v.id !== 'user_uber_classification')
      return
    }
    this.validatableInputs.push(document.getElementById('user_uber_classification'))
    targetFields.classList.remove('d-none')
  }

  render(target, validation) {
    let validator = validation.find((validator) => !validator.result.valid)
    if (validator) {
      this.renderValidation(target, validator)
    } else {
      this.clearValidation(target, validator)
    }
  }

  clearValidation(target, validator) {
    target.classList.remove('invalid')

    let help = target.closest('fieldset').querySelector('.error-label')
    help.classList.remove('show')
    help.innerHTML = ''
  }

  renderValidation(target, validator) {
    target.classList.add('invalid')
    let help = target.closest('fieldset').querySelector('.error-label')
    help.classList.add('show')
    help.innerHTML = `${validator.result.message}<br>`
  }

  validableUberClassification() {
    const companyInput = this.element.querySelector('input[name="user[company]"]:checked')
    if(companyInput.value == 'didi') {
      this.validatableInputs = this.validatableInputs.filter(v => v.id !== 'user_uber_classification')
    }
    const companyHolders = document.querySelectorAll('.company-holder')
    const company = companyInput.value
    this.showUberClassificationField(company)
    companyHolders.forEach(ch => ch.innerHTML = company)
  }
}
