import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ['mainContainer']
  
  connect(){
    let params = new URLSearchParams()
    if (this.mainContainerTarget.dataset.make){
      params.append('make',this.mainContainerTarget.dataset.make)
      params.append('code',this.mainContainerTarget.dataset.branchOfficeCode)
      params.append('tag_id',this.mainContainerTarget.dataset.tagId)
      get(`${this.mainContainerTarget.dataset.url}?${params}`,{
        responseKind: "turbo-stream"
      })
    }
  }

  get_data(e){
    let params = new URLSearchParams()
    params.append('make',e.currentTarget.value)
    params.append('code',this.mainContainerTarget.dataset.branchOfficeCode)
    params.append('tag_id',this.mainContainerTarget.dataset.tagId)
    params.append('car_assignment',this.mainContainerTarget.dataset.carAssignment)

    get(`${this.mainContainerTarget.dataset.url}?${params}`,{
      responseKind: "turbo-stream"
    })
  }
}
