import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    eventName: { type: String, default: "notset" }
  }
  connect() {
    this.setUpEvent()

    this.lauchEvent()
  }

  setUpEvent() {
    this.customEvent = new CustomEvent(this.eventNameValue)
  }

  lauchEvent() {
    if(this.notSendable()) return

    document.body.dispatchEvent(this.customEvent)
  }

  notSendable() {
    return this.eventNameValue === "notset"
  }
}
