export default class {
  constructor(element) {
    this._element = element
    this.cleanEvents()
    this.setupEvents()
  }

  setupEvents() {
    this._element.addEventListener('dragover', event => this.dragOverAction(event), false)
    this._element.addEventListener('dragleave', event => this.dragLeaveAction(event), false)
    this._element.addEventListener('drop', event => this.dropAction(event), false)
  }

  dragOverAction(event) {
    this._element.classList.add(this._element.dataset.dragOverClass)
  }

  dragLeaveAction(event) {
    this._element.classList.remove(this._element.dataset.dragOverClass)
  }

  dropAction(event) {
    this._element.classList.remove(this._element.dataset.dragOverClass)
    this._element.classList.add(this._element.dataset.dropClass)
  }

  cleanEvents() {
    this.defaultEvents.forEach(event => {
      this._element.addEventListener(event, (e) => {
        e.preventDefault()
        e.stopPropagation()
      })
    })
  }

  get defaultEvents() {
    return ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop']
  }

  get element() {
    return this._element
  }
}
