import Toast from '../utils/toast';
import { inputCreate } from '../helpers';
import { Modal } from 'bootstrap'
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    years: Array,
    periods: Array,
    fuels: { type: Array, default: ['gasoline', 'gnv'] }
  }

  connect() {
    this.setQueryDefaults()
    this.initTab()
    this.activateLeasingProductModel()
    this.setModal()
  }

  setQueryDefaults() {
    const queryDefaults = [['brand', ''], ['fuel', ''], ['year', ''],
      ['periods', ''], ['model', ''], ['version', ''], ['guarantee_deposit', ''],
      ['new_payment', '']];
    this.searchCriteria = new Map(queryDefaults);
  }

  updateSearchCriteria(key, value) {
    this.backupCriteria = new Map([...this.searchCriteria]);
    this.searchCriteria.set(key, value);
  }

  getQuery() {
    let query = '';
    if (!this.searchCriteria.get('brand') == '') query += `[data-tab-brand="${this.searchCriteria.get('brand')}"]`;
    if (!this.searchCriteria.get('fuel') == '') query += `[data-tab-fuel="${this.searchCriteria.get('fuel')}"]`;
    if (!this.searchCriteria.get('year') == '') query += `[data-tab-year="${this.searchCriteria.get('year')}"]`;
    if (!this.searchCriteria.get('periods') == '') query += `[data-tab-periods="${this.searchCriteria.get('periods')}"]`;
    if (!this.searchCriteria.get('model') == '') query += `[data-tab-model="${this.searchCriteria.get('model')}"]`;
    if (!this.searchCriteria.get('version') == '') query += `[data-tab-version="${this.searchCriteria.get('version')}"]`;
    return query;
  }

  activateLeasingProductModel() {
    const leasingProductId = this.element.dataset.leasingProductId;
    let currentModel = this.element.querySelector('.image-radio__container').dataset.tabModel
    this.updateSearchCriteria('model', this.element.dataset.leasingProductModel || currentModel);
    const models = this.element.querySelectorAll('.image-radio__container');
    models.forEach((model) => {
      const leasing_id = model.dataset.tabId;
      model.classList.toggle('active', leasing_id === leasingProductId);
    });
  }

  setModal(){
    this.selectionModalTrigger = document.querySelectorAll('.selection-modal-trigger')
    this.closeModalButton = document.querySelector('.car-selection-modal-close')
    this.selectionModalTrigger.forEach(element => {
      element.addEventListener('click', event => {
        const modal = Modal.getInstance(document.getElementById('car-selection-modal'))
        modal.show()
      })
    })
    if(this.closeModalButton) {
      this.closeModalButton.addEventListener('click', event => {
        const modal = Modal.getInstance(document.getElementById('car-selection-modal'))
        modal.hide()
      })
    }
  }

  initTab() {
    document.body.addEventListener('leasing-product-tab:changed', (event) => {
      event.detail.forEach((value, key) => {
        this.updateSearchCriteria(key, value);
      });
      this.updateTab();
      this.updateAmounts();
    });
  }

  updatePriceContainer(e){
    const container_guarantee_deposit = document.querySelectorAll('.price-container .image-radio__pricing .container-guarantee-deposit')
    const container_weekly_payment = document.querySelectorAll('.price-container .image-radio__pricing .container-weekly-payment')
    const container_periods = document.querySelectorAll('.price-container .image-radio__select .container-periods')
    container_guarantee_deposit.forEach((element) => {
      element.innerHTML = (this.searchCriteria.get('guarantee_deposit') || this.element.firstElementChild.dataset.tabGuaranteeDeposit) + " mxn"
    });
    container_weekly_payment.forEach((element) => {
      element.innerHTML = (this.searchCriteria.get('new_payment') || this.element.firstElementChild.dataset.tabWeeklyPayment) + " mxn"
    });
    container_periods.forEach((element) => {
      element.innerHTML = (this.searchCriteria.get('periods') || this.element.firstElementChild.dataset.tabPeriods) + " meses"
    });
  }

  updateAmounts() {
    this.updateGuaranteeAmount();
    this.updatePaymentAmount();
  }

  updateGuaranteeAmount() {
    let guarantee = 0
    if (typeof this.searchCriteria.get('guarantee_deposit') === 'number') {
      guarantee = this.searchCriteria.get('guarantee_deposit')
    } else{
      guarantee = this.searchCriteria.get('guarantee_deposit')?.replace(',', '')
    }
    const guaranteeDeposit = parseFloat(guarantee);
    const currencyFormat = { style: 'currency', currency: 'USD', maximumSignificantDigits: 9 };
    const formatedDeposit =  guaranteeDeposit.toLocaleString('en-US', currencyFormat);
    if(guaranteeDeposit <= 0) {
      document.getElementById('input-guarantee-deposit')?.remove();
      return;
    }

    this.updateElemenText('.guarantee-deposit', formatedDeposit);
    this.updateOrCreateInput('guarantee_deposit', guaranteeDeposit);
  }

  updatePaymentAmount() {
    const currencyFormat = { style: 'currency', currency: 'USD', maximumSignificantDigits: 9 };
    const newPaymentVal = this.searchCriteria.get('new_payment');
    const formatedPaymentVal = newPaymentVal.toLocaleString('en-US', currencyFormat);
    if(newPaymentVal <= 0) {
      document.getElementById('input-weekly-payment')?.remove();
      return;
    }

    this.updateElemenText('.price-label', formatedPaymentVal);
    this.updateOrCreateInput('weekly_payment', newPaymentVal);
  }

  updateOrCreateInput(selector, newValue) {
    const form = document.querySelector('form');
    const elementId = "input-" + selector.replace(/\_/g, '-');
    const targetInput = document.getElementById(elementId);
    if(targetInput) {
      targetInput.value = newValue;
    } else {
      const newInput = inputCreate({ type: 'hidden', name: selector, id: elementId, value: newValue });
      form.appendChild(newInput);
    }
  }

  updateElemenText(selector, newValue) {
    const activeTab = document.querySelector('.image-radio__container.active');
    const targetElement = activeTab.querySelector(selector);
    targetElement.textContent = newValue;
  }

  updateTab(){
    var tab = this.element.querySelector(this.getQuery());
    var currentActive = this.element.querySelector('.image-radio__container.active');
    if(tab === null) {
      const errorMessage = new Toast('No hay autos con estas características', true);
      errorMessage.show();
      this.searchCriteria  = new Map(this.backupCriteria);
      tab = this.element.querySelector(this.getQuery());
    }
    if (currentActive && tab) {
      currentActive.classList.remove('active', 'show');
    }
    if (tab) {
      tab.classList.add('show', 'active');
    }
  }
}
