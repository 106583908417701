import { Controller } from "stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  async connect(){
    this.settings = await this.getSettings()
    this.setUp()
  }

  setUp(){
    let renderConfig = {
      "configuration": {
        "externalId": this.settings.user_id,
        "countries": ["mx"],
        "isSandbox": this.settings.isSandbox,
        "platforms": [this.settings.platform],
        "hideConsent": true,
        "hideLogo": true,
        "hideWhatsApp": true,
        "lang": 'es',
        "customPrivacyUrl": this.settings.termsAndConditionsUrl
      },
      appearance: {
        primaryColor: "#606060",
        borderRadius: "9999px"
      }
    }

   window.palencaController = this

    let link = palenca.loadLink(this.settings.public_key, this.settings.widget_id).then(link => {
      link.on("ready", () => {
        console.log("Widget is ready")
      })

      link.on("user_created", (event) => {
        console.log("User created", event)
      })

      link.on("connection_success", (event) => {
        window.location.replace(`${window.palencaController.settings.waitingRoomUrl}?user_id=${event.data.user_id}&account_id=${event.data.account_id}`);
      })

      link.on("connection_error", (event) => {
        console.log(`Connection error ${event.data.error.code}`)
      })

      link.render("palenca_container", renderConfig);
      
      window.addEventListener('unload', () => {
          link.destroy()
      })

    }, error => {
      console.log(error);
    })
  }
  
  async getSettings(){
    const response = await get('/palenca/options')
    if (response.ok) {
      return await response.json
    }
  }
}

