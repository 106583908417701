import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.navLinks = Array.from(this.element.querySelectorAll('.nav-link'))
    this.navLinks.forEach(element => {
      element.addEventListener('click', event => this.updateTab(event))
    })
  }

  updateTab(event) {
    this.deactivateItems()
    event.currentTarget.classList.add('active')
  }

  deactivateItems() {
    this.findCurrentActive().forEach(element =>  {
      element.classList.remove('active')
    })
  }

  findCurrentActive() {
    return Array.from(this.element.querySelectorAll('.nav-link.active'))
  }
}
