import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    target: String
  }

  addRelationFields(e) {
    const link  = document.getElementById(this.targetValue)
    if(!this.activeFields()) {
      link.click()
      this.target.classList.remove('d-none')
    }
  }

  activeFields() {
    let hiddenNestedFields = Array.from(this.nestedFields).filter( i => i.style.display === 'none')
    return this.nestedFields.length - hiddenNestedFields.length > 0
  }

  closeFields() {
    if(this.activeFields()) {
      this.nestedFields.forEach( nf => {
        nf.querySelector('.remove_fields').click()
      })
    }
    this.target.classList.add('d-none')
  }

  get target() {
    return document.querySelector(`#${this.targetValue}-fields`)
  }

  get nestedFields() {
    //const relationsContainer = document.querySelector(`#${this.targetValue}-fields`)
    return this.target.querySelectorAll('.nested-fields')
  }
}
