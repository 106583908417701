import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['password']

  connect() {
    this.sent = false
  }

  validateForm(){
    if(this.passwordTarget.value.length > 0){
      this.submitForm()
    }
  }

  submitForm() {
    if(!this.sent) {
      this.sent = true
      this.element.requestSubmit()
    }
  }
}


