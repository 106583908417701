import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'

export default class extends Controller {
  static targets = ['modal']
  connect() {
  }

  showModal() {
    this.modal.show()
  }

  get modal() {
    return new Modal(this.modalTarget)
  }
}
