import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules'

export default class extends Controller {
  connect() {
    Swiper.use([Navigation, Pagination]);

    const swiper = new Swiper('.documents-swiper', {
      speed: 400,
      loop: false,
      spaceBetween: 30,
      slidesPerView: 2.3,
      navigation: {
        nextEl: '.next-button'
      },
      breakpoints: {
        768: {
          slidesPerView: 3.5,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        }
      }
    })
  }
}
