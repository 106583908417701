import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {

  connect() {
    this.form = this.element
    this.form.addEventListener('broadcast', (e) => {
        this.get_data(e)
    })
  }

  get_data(e){
    e.preventDefault()
    e.stopPropagation()

    let params = new URLSearchParams()
    params.append('q',this.form.querySelector("#q")?.value)
    params.append('status',this.form.querySelector("#status")?.value)
    params.append('active_tab',this.form.querySelector("#active_tab")?.value)

    if (this.form.querySelector("#status")?.value.length != 0){
      params.append('status',this.form.querySelector("#status")?.value)
    }
    else{
      params.append('status',this.form.querySelector("#status_2")?.value)
    }

    if (this.form.querySelector("#content_type")?.value.length != 0){
      params.append('content_type',this.form.querySelector("#content_type")?.value)
    }
    else{
      params.append('content_type',this.form.querySelector("#content_type_2")?.value)
    }

    if (this.form.querySelector("#category")?.value.length != 0){
      params.append('category',this.form.querySelector("#category")?.value)
    }

    let any_date = this.form.querySelector("#any_date")?.checked
    if (any_date == false){
      params.append('first_date',this.form.querySelector("#first_date")?.value)
      params.append('last_date',this.form.querySelector("#last_date")?.value)
    }

    get(`${window.location.href.split('?')[0]}?${params}`,{
      responseKind: "turbo-stream"
    })
  }

  toggleDates(e){
    this.form.querySelector("#first_date").toggleAttribute("disabled")
    this.form.querySelector("#last_date").toggleAttribute("disabled")
  }
}
