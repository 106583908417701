import { Modal } from 'bootstrap'

export default class {
  constructor(modalTitle = "modal title", modalBodyContent = "<p>Hello</p>") {
    this.modalTitle = modalTitle
    this.modalBodyContent = modalBodyContent
    this.buildModal()
  }

  getBootstrapModal() {
    return new Modal(this.modalContainer)
  }

  buildModal() {
    this.setModalElements()
    this.modalContent.appendChild(this.modalHeader)
    this.modalContent.appendChild(this.modalBody)
    this.modalContent.appendChild(this.modalFooter)
    this.modalDialog.appendChild(this.modalContent)
    this.modalContainer.appendChild(this.modalDialog)
  }

  setModalElements() {
    this.modalContainer = this.setModalContainer()
    this.modalDialog = this.setModalDialog()
    this.modalContent = this.setModalContent()
    this.modalHeader = this.setModalHeader()
    this.modalBody = this.setModalBody()
    this.modalFooter = this.setModalFooter()
  }

  setModalContainer() {
    const modalContainer = document.createElement('div')
    modalContainer.classList.add('modal')
    modalContainer.classList.add('fade')
    modalContainer.setAttribute('tabindex', '-1')
    modalContainer.setAttribute('role', 'dialog')
    modalContainer.id = 'exampleModal'
    return modalContainer
  }

  setModalDialog() {
    const modalDialog = document.createElement('div')
    modalDialog.classList.add('modal-dialog')
    modalDialog.setAttribute('role', 'document')
    return modalDialog
  }

  setModalContent() {
    const modalContent = document.createElement('div')
    modalContent.classList.add('modal-content')
    return modalContent
  }

  setModalHeader() {
    const modalHeader = document.createElement('div')
    modalHeader.classList.add('modal-header')
    modalHeader.appendChild(this.setHeaderTitle())
    modalHeader.appendChild(this.setDismissButton())
    return modalHeader
  }

  setModalBody() {
    const modalBody = document.createElement('div')
    modalBody.classList.add('modal-body')
    modalBody.appendChild(this.setBodyContent())
    return modalBody
  }

  setModalFooter() {
    const modalFooter = document.createElement('div')
    modalFooter.classList.add('modal-footer')
    return modalFooter
  }

  setDismissButton() {
    const button = document.createElement('button')
    button.classList.add('btn-close')
    button.setAttribute('type', 'button')
    button.setAttribute('data-bs-dismiss', 'modal')
    button.setAttribute('aria-label', 'Close')
    return button
  }

  setHeaderTitle(txt = '') {
    if(txt === '')
      txt = this.modalTitle
    const title = document.createElement('h4')
    title.classList.add('modal-title')
    title.innerHTML = txt
    return title
  }

  setBodyContent(content = '') {
    if(content === '')
      content = this.modalBodyContent
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(content, 'text/html');
    const element = document.createElement('div')
    element.innerHTML = parsedHtml.body.innerHTML
    return element
  }
}
