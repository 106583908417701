import { Toast } from 'bootstrap'

export default class {
  constructor(message, dismissible = false, delay = 3000) {
    this.message = message
    this.setupToast()
    this.toast = new Toast(this.toastDialog, { autohide: dismissible, delay: delay })
    return this.toast
  }

  setupToast() {
    this.toastDialog = this.toastDialog()
    const toastContainer = this.toastContainer()
    this.toastDialog.appendChild(this.toastContent())
    toastContainer.appendChild(this.toastDialog)
    this.toastHtml = toastContainer
    document.body.appendChild(this.toastHtml)
  }

  toastContainer() {
    const container = document.createElement('div')
    container.classList.add('position-fixed',  'top-0', 'start-1', 'end-0', 'px-1', 'py-4')
    // the value 1056 is because modals have a 1055 z-index value and this most be over modals
    container.style = 'z-index: 1056;'
    container.addEventListener('hidden.bs.toast', event => { container.remove() })
    return container
  }

  toastDialog() {
    const dialog = document.createElement('div')
    dialog.classList.add('toast', 'align-items-center', 'text-white', 'bg-warning', 'border-0')
    dialog.setAttribute('role', 'alert')
    dialog.setAttribute('aria-live', 'assertive')
    dialog.setAttribute('aria-atomic', 'true')
    return dialog
  }

  toastContent() {
    const content = document.createElement('div')
    content.classList.add('d-flex')
    content.appendChild(this.toastBody())
    content.appendChild(this.toastDismissButton())
    return content
  }

  toastBody() {
    const body = document.createElement('div')
    body.classList.add('toast-body')
    body.textContent = this.message
    return body
  }

  toastDismissButton() {
    const btn = document.createElement('button')
    btn.classList.add('btn-close', 'btn-close-white', 'me-2', 'm-auto')
    btn.setAttribute('type', 'button')
    btn.setAttribute('data-bs-dismiss', 'toast')
    btn.setAttribute('aria-label', 'Close')
    return btn
  }
}
