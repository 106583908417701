import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  delete(event) {
    event.preventDefault()

    this.#updateDestroyField()
    this.#removeElement()
    this.#notifyDeletion()
  }

  #updateDestroyField() {
    const destroyField = this.element.querySelector('.deletion-value')

    if(destroyField) destroyField.value = true
  }

  #removeElement() {
    this.element.classList.add('d-none')
    this.element.removeAttribute('data-index')
  }

  #notifyDeletion() {
    const customEvent = new CustomEvent('questionnaire:questionDeleted', {
      bubbles: true
    })
    this.element.dispatchEvent(customEvent);
  }
}
