import { Controller } from "@hotwired/stimulus"
import CommentableFieldsCreator from '../shared/issues/commentable_fields_creator'

export default class extends Controller {
  static targets = ['controlsSection']

  connect() {
    this.imageFrame = this.element.querySelector('.form-image')
  }

  updateStatus(event) {
    const select = event.currentTarget
    console.log(select.value)
    switch (select.value) {
      case 'completed':
        this.removeCommentForm()
        if(this.imageFrame.classList.contains('image-addressed-issue')) return

        this.imageFrame.classList.remove('image-pending-issue')
        this.imageFrame.classList.add('image-addressed-issue')
        break
      case 'rejected':
        this.renderCommentForm()
        if(this.imageFrame.classList.contains('image-pendding-issue')) return

        this.imageFrame.classList.remove('image-addressed-issue')
        this.imageFrame.classList.add('image-pending-issue')
        break
      default:
        console.error("Undefined option")
        break
    }
  }

  renderCommentForm() {
    const creator = new CommentableFieldsCreator({
      sectionId: this.controlsSectionTarget.dataset.fieldIndex,
      modelName: this.controlsSectionTarget.dataset.fieldModel,
      currentUserId: this.controlsSectionTarget.dataset.currentUserId,
      issuableId: this.controlsSectionTarget.dataset.issuableId,
      issuableType: this.controlsSectionTarget.dataset.issuableType
    })
    this.controlsSectionTarget.appendChild(creator.getCommentableFormGroup())
  }

  removeCommentForm() {
    const commentForm = this.element.querySelector(`#commentable-${this.controlsSectionTarget.dataset.fieldIndex}`)
    if(!commentForm) return

    commentForm.remove()
  }
}
