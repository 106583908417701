import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'

export default class extends Controller {
  static targets = ['commentArea', 'documentIds', 'submitButton']

  connect() {
    this.element.addEventListener('submit', event => { this.submit(event) })
    this.documentContainer = this.element.querySelector('#user_pld_report_version_id')
    this.commentAreaTarget.addEventListener('keyup', event => { this.enableSubmit(event) })
    this.documentIdsTarget.addEventListener('change', event => { this.enableSubmit(event) })
    this.enableSubmit()
  }

  submit(event){
    if(this.validForm()) {
      this.element.requestSubmit()
    } else {
      event.preventDefault()
      event.stopPropagation()
      this.renderErrors()
    }
  }

  enableSubmit(event) {
    this.removeErrors()
    if(!(this.validComment() && this.validDocuments())) {
      this.submitButtonTarget.disabled = true
      return
    }

    this.submitButtonTarget.disabled = false
  }

  validForm() {
    return this.validComment() && this.validDocuments()
  }

  validComment() {
    return this.commentAreaTarget.value !==  ''
  }

  validDocuments() {
    return this.documentIdsTarget.value !== ''
  }

  renderErrors() {
    this.commentErrors()
    this.documentErrors()
  }

  removeErrors() {
    if(this.validComment()) this.commentAreaTarget.classList.remove('invalid')
    if(this.validDocuments()) this.documentContainer.classList.remove('invalid')
  }

  commentErrors() {
    if(this.validComment()) return

    this.commentAreaTarget.classList.add('invalid')
  }

  documentErrors() {
    if(this.validDocuments()) return

    this.documentContainer.classList.add('invalid')
  }
}
