import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['optionalField', 'optionalSection']

  connect() {
    this.optionalFieldTarget.addEventListener('blur', () => {
      this.validateOptionalField()
    })
  }

  showTarget(event) {
    this.optionalSectionTarget.classList.remove('d-none')
    this.optionalFieldTarget.value = ''
  }

  hideTarget(event) {
    this.optionalSectionTarget.classList.add('d-none')
    this.optionalFieldTarget.value = ''
  }

  validateOptionalField() {
    const validatable = this.optionalFieldTarget.dataset.required
    if (validatable && !this.optionalFieldTarget.value) {
      this.renderError()
    }else{
      this.hideError()
    }
  }

  renderError() {
    const errorLabel = this.optionalSectionTarget.querySelector('.error-label')
    errorLabel.innerHTML = 'Campo obligatorio'
    errorLabel.style.display = 'block'
    this.optionalFieldTarget.classList.add('invalid')
  }

  hideError() {
    const errorLabel = this.optionalSectionTarget.querySelector('.error-label')
    errorLabel.innerHTML = ''
    errorLabel.style.display = 'none'
    this.optionalFieldTarget.classList.remove('invalid')
  }
}
