import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['coordsField']

  getCoords(_event) {
    if (!navigator.geolocation) {
      alert('El navegador no tiene soporte para Geolocalización')
    } else {
      navigator.geolocation.getCurrentPosition((location) => {
        this.coordsFieldTarget.value = `${location.coords.latitude}, ${location.coords.longitude}`
      })
    }
  }
}
