import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener('input', (event) => {
      let target = event.currentTarget
      let value = target.value
      target.value = this.sanitizeNumber(value)
    })
  }

  sanitizeNumber(str) {
    if(!str) return

    return str.replace(/[^0-9.,]/g, '')
  }
}
