export default class {
  createLabel(message, targetId) {
    const label = document.createElement('label')
    label.setAttribute('for', `${targetId}`)
    label.classList.add('control-label')
    label.textContent = message
    return label
  }

  createHiddenField(name, value) {
    const hiddenField = document.createElement('input')
    hiddenField.type = 'hidden'
    hiddenField.name = name
    hiddenField.value = value
    return hiddenField
  }
}
