export default class BaseValidator {
  constructor(value, options, errorMessages, id, newRecord) {
    this.value = value
    this.options = options
    this.errorMessages = errorMessages
    this.id = id
    this.newRecord = newRecord
  }

  validate() {
    return { valid: false, message: 'Implement me!'}
  }
}
