import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.fields = Array.from(this.element.querySelectorAll('input.required'))
    this.form = document.querySelector('.onboarding-form')
    this.bindFields()
    this.evaluateForm()
  }

  bindFields() {
    this.fields.forEach(input => {
      input.addEventListener('input', (e) => {
        this.evaluateForm()
      })
    })
  }

  evaluateForm() {
    const anyNull = this.fields.some(input => { return input.value == '' })
    if (!anyNull) {
      const enableEvent = new CustomEvent('enable-form', {})
      this.form.dispatchEvent(enableEvent)
    }
  }
}
