import BaseCreator from './base_creator'
import CommentableFieldsCreator from './commentable_fields_creator'

// Para evitar mucha logica de negocio en las vistas opte por generar todo el template desde
// javascript, cada que se agrega o elimina se borra el elemento del dom completamente, en lugar
// de hacer malabares con display o agregando y quitando clases o algo mas con erb
export default class extends BaseCreator {
  #sectionId
  #modelName
  #issuableId
  #issuableType
  #revisionNumber
  #commentableCreator

  constructor(options) {
    super()
    this.#sectionId = options.sectionId
    this.#modelName = options.modelName
    this.#issuableId = options.issuableId
    this.#issuableType = options.issuableType
    this.#revisionNumber = options.revisionNumber
    this.#commentableCreator = new CommentableFieldsCreator(options)
  }

  getIssuableForm() {
    const mainContainer = this.#prepareContainer()
    return mainContainer
  }

  #prepareContainer() {
    const fieldsContainer = document.createElement('div')
    fieldsContainer.classList.add('form-group', 'issuable-form')
    fieldsContainer.setAttribute('id', `container-${this.#sectionId}`)
    fieldsContainer.appendChild(this.#commentableCreator.createUserId)
    fieldsContainer.appendChild(this.#createIssuableId)
    fieldsContainer.appendChild(this.#createIssuableType)
    fieldsContainer.appendChild(this.#createRevisionNumber)
    fieldsContainer.appendChild(this.#commentableCreator.createCommentTitle())
    fieldsContainer.appendChild(document.createElement('hr'))
    fieldsContainer.appendChild(this.#commentableCreator.createCommentMessage())

    return fieldsContainer
  }

  get #createRevisionNumber() {
    return this.createHiddenField(`${this.#modelName}[${this.#sectionId}][revision_number]`,
                                  this.#revisionNumber)
  }

  get #createIssuableId() {
    return this.createHiddenField(
      `${this.#modelName}[${this.#sectionId}][issuable_id]`,
      this.#issuableId
    )
  }

  get #createIssuableType() {
    return this.createHiddenField(
      `${this.#modelName}[${this.#sectionId}][issuable_type]`,
      this.#issuableType
    )
  }
}
