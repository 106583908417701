import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  showTab(event) {
    event.preventDefault()
    const activeButton = this.element.querySelector('.controlls .control-item.active')
    const controlTarget = event.currentTarget.parentElement
    const tabTarget = this.element.querySelector(`${event.currentTarget.dataset.tabTarget}`)

    if(this.#isActive(event.currentTarget)) return

    this.#deactivateElement(this.#findActiveElement())
    this.#activateElement(tabTarget)
    this.#deactivateElement(activeButton)
    this.#activateElement(controlTarget)
  }

  #isActive(element) {
    return element.classList.contains('active')
  }

  #findActiveElement() {
    return this.element.querySelector('.tab-content .tab-pane.active')
  }

  #activateElement(element) {
    element.classList.add('active')
  }

  #deactivateElement(element) {
    element.classList.remove('active')
  }
}
