import { Controller } from "stimulus"
import { patch } from "@rails/request.js"

export default class extends Controller {
  static targets = ['reassigedCarContainer', 'newCarContainer']

  connect() {
    this.isReassigned = this.element.dataset.reassigned == 'true'

    this.toggleReassignedView()
    this.toggleMainView()
  }

  cancel(e){
    this.toggleReassignedView()
  }

  changeToReassignedView(e){
    this.isReassigned = true
    this.toggleMainView()
  }

  changeToNewView(e){
    if(confirm('¿Estás seguro de eliminar la información del seminuevo al cambiarlo a nuevo?')){
      this.resetReassignedValues()
      this.isReassigned = false
      this.toggleMainView()
    }
  }

  toggleEdition(){
    document.querySelectorAll('.car-info-show-box').forEach(element => {
      element.style.display = 'none'
    });

    document.querySelectorAll('.car-info-update-box').forEach(element => {
      element.style.display = 'block'
    });
  }

  toggleReassignedView(){
    document.querySelectorAll('.car-info-show-box').forEach(element => {
      element.style.display = 'block'
    });

    document.querySelectorAll('.car-info-update-box').forEach(element => {
      element.style.display = 'none'
    });
  }

  toggleMainView(){
    if(this.isReassigned){
      this.reassigedCarContainerTarget.style.display = 'block'
      this.newCarContainerTarget.style.display = 'none'
    }else{
      this.reassigedCarContainerTarget.style.display = 'none'
      this.newCarContainerTarget.style.display = 'block'
    }
  }

  async resetReassignedValues(){
    const response = await patch(
        `/admin/loan_applications/${this.element.dataset.loanApplicationId}/update_car_info.js`, 
        { body: JSON.stringify({ reassigned_car_model: '', reassigned_car_vin: '', reassigned_car_weekly_payment: '', reassigned_car_periods: '' }) }
    )
  }
}


