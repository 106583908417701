import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"
import { destroy } from '@rails/request.js'
import { streamVideo } from '../camera/camera_access'
import { dataURLtoBlob } from '../helpers'
import { Modal } from 'bootstrap'
import CameraModal from '../camera/screenshot_camera_modal'

export default class extends Controller {
  static values = {
    imageFrame: String,
    imageInput: String,
    fileUploadControl: String,
    modalTitle: { type: String, default: ''}
  }
  static targets = ['addButton', 'deleteButton']

  connect() {
    this.bindModal()
    this.isMobile = window.isMobile()
    this.input = document.getElementById(this.imageInputValue)
    this.imageFrame = document.getElementById(this.imageFrameValue)
    this.progressContainer = this.element.querySelector('.progress-container')
    this.progressBar = this.progressContainer.querySelector('.progress .progress-bar')
    this.errorLabel = this.imageFrame.parentNode.querySelector('.error-label')
    this.screenShotEvent = new CustomEvent('form:changed', {});
    const defaultImage = this.imageFrame.querySelector('.default-image')
    if(defaultImage) {
      this.defaultImagePath = defaultImage.src
    }
    this.fileUploadControls = document.getElementById(this.fileUploadControlValue)
  }

  bindModal() {
    const cameraModal = new CameraModal(this.modalTitleValue)
    this.modal = cameraModal.getBootstrapModal()
    this.video = cameraModal.modalContainer.querySelector('video')
    this.canvas = cameraModal.modalContainer.querySelector('canvas')
    cameraModal.modalContainer.querySelector('button.btn-close').addEventListener('click', () => { this.dismissModal() })
    cameraModal.modalContainer.querySelector('button.take-screenshot').addEventListener('click', (e) => { this.takeScreenshot(e) })
  }

  showModal() {
    if(this.isMobile) {
      this.input.setAttribute('accept', 'image/*')
      this.input.setAttribute('capture', 'capture')
      this.input.click()
    } else {
      this.modal.show()
      streamVideo(this.video)
    }
  }

  takeScreenshot(e) {
    this.canvas.width = this.video.videoWidth
    this.canvas.height = this.video.videoHeight
    this.canvas.getContext('2d').drawImage(this.video, 0, 0)

    this.handleScreenshot(this.canvas.toDataURL('image/webp'))
  }

  handleScreenshot(fileData) {
    let file = dataURLtoBlob(fileData)
    if (file.size <= 0) {
      alert("Por favor habilita la cámara de tu navegador para continuar.")
      return
    }
    this.callbackActions(file)
  }

  callbackActions(file) {
    this.progressContainer.classList.remove('d-none')
    this.updaloadImage(file)
    this.dismissModal()
  }

  showPhoto(file){
    const urlCreator = window.URL || window.webkitURL
    const img = this.imageFrame.querySelector('.default-image')
    const imgUrl = urlCreator.createObjectURL(file)
    this.errorLabel.style.display = 'none'
    this.imageFrame.classList.remove('upload-area--invalid')
    this.imageFrame.classList.add('upload-area--loaded')
    img.src = imgUrl
    img.style.width = '100%'
    img.style.height = '100%'
    img.classList.remove('d-none')
    img.alt = "house-picture"
    document.body.dispatchEvent(this.screenShotEvent)
  }

  updaloadImage(file) {
    const fileName = `addreess_picture.${file.type.split('/')[1]}`
    const fileContent = new File([file], fileName)
    const url = this.input.dataset.directUploadUrl
    const upload = new DirectUpload(fileContent, url, this)
    upload.create((error, blob) => {
      if (error) {
        console.log(error)
      } else {
        this.appendFileIdToForm(blob.signed_id)
        this.hideAddButton()
        this.appendDeleteButton(blob.signed_id)
        this.input.setAttribute('data-file-attached', 'true')
        this.showPhoto(file)
        this.progressContainer.classList.add('d-none')
        this.progressBar.style.width = '0%';
      }
    })
  }

  async deleteUnattachedImage(e) {
    e.preventDefault()
    const blobIdField = document.querySelector(`input[name='${this.input.name}'][type=hidden]`)
    blobIdField.remove()
    const url = e.currentTarget.dataset.destroyPath
    const response = await destroy(url, {})
    if (response.ok) {
      this.imageFrame.classList.remove('upload-area--loaded')
      this.deleteButtonTarget.classList.add('d-none')
      this.addButtonTarget.classList.remove('d-none')
      this.fileUploadControls.classList.remove('d-none')
      this.input.setAttribute('data-file-attached', 'false')
      const img = this.imageFrame.querySelector('.default-image')
      img.style.width = '70px'
      img.style.height = '70px'
      img.classList.add('d-none')
      img.src = this.defaultImagePath
    }
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", event => {
      this.directUploadDidProgress(event)
    })
  }

  directUploadDidProgress(event) {
    this.progressBar.style.width = `${event.loaded * 100 / event.total}%`;
    this.progressBar.textContent = `${(event.loaded * 100 / event.total).toFixed(1)}%`
  }

  appendFileIdToForm(blob_id) {
    // Add an appropriately-named hidden input to the form with a
    //  value of blob.signed_id so that the blob ids will be
    //  transmitted in the normal upload flow
    let hiddenField = document.createElement('input')
    hiddenField.setAttribute("type", "hidden")
    hiddenField.setAttribute("value", blob_id)
    hiddenField.setAttribute("name", this.input.name)
    this.input.remove()
    document.querySelector('form').appendChild(hiddenField)
  }

  appendDeleteButton(blob_id) {
    const a = this.deleteButtonTarget
    a.setAttribute("data-destroy-path", `/storage/${blob_id}`)
    a.setAttribute('data-action', 'camera-screenshot#deleteUnattachedImage')
    a.classList.remove('d-none')
  }

  hideAddButton() {
    this.addButtonTarget.classList.add('d-none')
    this.fileUploadControls.classList.add('d-none')
  }

  dismissModal() {
    this.modal.hide()
    this.stopStreaming()
  }

  stopStreaming(){
    if (this.video.srcObject !== null)
      this.video.srcObject.getTracks()[0].stop()
  }
}
