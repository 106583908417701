import { Controller } from "@hotwired/stimulus"
import QuestionsArrangement from '../shared/questionnaires/questions_arrangement'
import QuestionCreator from '../shared/questionnaires/nested_question_creator'

export default class extends Controller {
  #questionsArrangement

  #validEvents = {
    sort: 'questionnaire:onquestionsSort',
    deleted: 'questionnaire:questionDeleted',
    selected: 'questionnaire:selectedQuestions'
  }

  static targets = ['question']

  connect() {
    this.#bindEvents()
    this.#questionsArrangement = new QuestionsArrangement(this.element)
    this.#questionsArrangement.start()
  }

  #updatePositionValues(_event) {
    this.questionTargets.forEach(element => {
      const newPositionValue = element.classList.contains('d-none') ? '' : element.dataset.index
      element.querySelector('.position-value').value = newPositionValue
    })
  }

  #bindEvents() {
    this.element.addEventListener(this.#validEvents.sort, e => { this.#updatePositionValues(e) })
    this.element.addEventListener(this.#validEvents.deleted, e => { this.#reorderList(e) })
    this.element.addEventListener(this.#validEvents.selected, e => {
      this.#appendQuestionsSelected(e.detail.questionsInfo)
    })
  }

  #reorderList(_event) {
    this.#questionsArrangement.reorderList()
  }

  #appendQuestionsSelected(questionsInfo) {
    const lastPosition = this.questionTargets.at(-1)?.dataset.index || 0
    let position = parseInt(lastPosition)
    const currentQuestionIds = this.questionTargets.map(el => el.querySelector('.question-id').value)
    const notIncludedIds = questionsInfo.filter(qInfo => !currentQuestionIds.includes(qInfo[0]))
    notIncludedIds.forEach(data => {
      position += 1
      const questionElement = QuestionCreator.build(data[0], this.element.dataset.pageId, position, data[1])
      this.element.appendChild(questionElement)
    })
  }
}
