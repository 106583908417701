import { Controller } from "stimulus"
import { get } from "@rails/request.js"

export default class extends Controller{
  static values = { redirectDone: Boolean }

  connect(){
    if(!this.redirectDoneValue){
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const lat = position.coords.latitude
          const lng = position.coords.longitude
          this.getStateByPosition(lat, lng)
        })
      } else {
        console.log("Geolocation is not supported by this browser.")
      }
    }
  }

  getStateByPosition(lat, lng){
    const latlng = {
      lat: lat,
      lng: lng,
    };
    const geocoder = new google.maps.Geocoder();
    geocoder
      .geocode({ location: latlng })
      .then((response) => {
        if (response.results[0]) {
          this.findStateFromGoogleMapsResponse(response)
        } else {
          console.log('no results from coordinates');
        }
      })
      .catch((e) => console.log("Geocoder failed due to: " + e));
  }

  findStateFromGoogleMapsResponse(response){
    let stateName = this.getStateCode(response)
    // Change location as long as isn´t the default location(Puebla - pl)
    // and it´s a state that we have operation
    const supportedBranchOffices = this.getSupportedBranchOffices()
    if(stateName && stateName !== 'Puebla' && supportedBranchOffices[stateName]){
      localStorage.removeItem('disclaimerStorage')
      this.redirectToHomeWithCurrentLocation(supportedBranchOffices[stateName])
    }
  }

  getStateCode(response){
    let addressComponent = {}
    response.results.find(address => {
      addressComponent = address.address_components.find(component => {
        return component.types.includes("administrative_area_level_1");
      })
      return addressComponent ? true : false
    })

    if(addressComponent){
      return addressComponent.long_name
    }else{
      return ''
    }
  }

  async getSupportedBranchOffices(){
    const response = await get('landing/branch_offices')
    if(response.ok){
      return await response.json
    }
  }

  redirectToHomeWithCurrentLocation(stateCode){
    const stateParam = window.location.search.length == 0 ? `?bo=${stateCode}` : `&bo=${stateCode}`
    window.location.href =  `${window.location.search}${stateParam}`
  }
}