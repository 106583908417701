import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'
import Swiper from 'swiper';
import { Autoplay,Navigation, Pagination } from 'swiper/modules'

export default class extends Controller {
  connect() {
    Swiper.use([Autoplay,Navigation, Pagination]);

    this.featureSelectors = document.querySelectorAll('[data-selector="feature-selector"]')
    this.callSwipers();
    this.animateHeader();
    this.animateUber();
    this.animeteTags()
    this.diclaimerModal()
    this.callSelectors()
  }

  diclaimerModal(){
    let disclaimerModal = new Modal(document.getElementById('disclaimerModal'))
    let disclaimerStorage = window.localStorage.getItem('disclaimerStorage')
    if(disclaimerStorage!="discalimerdisplay"){
      disclaimerModal.show()
      window.localStorage.setItem('disclaimerStorage','discalimerdisplay')
    }
  }

  callSelectors() {
    this.featureSelectors.forEach(selector => {
      selector.addEventListener('change', event => {
        const selectors = document.querySelectorAll(`#${selector.dataset.tabsArea} .tab-pane`)
        const elementSelected = document.getElementById(selector.value)

        selectors.forEach(selector => {
          selector.classList.remove('show', 'active')
        })
        elementSelected.classList.add('active', 'show')
      })
    })
  }

  callSwipers(){
    const swiper1 = new Swiper('.vehicle-swiper', {
      loop: false,
      slidesPerView: 1.1,
      spaceBetween: 15,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
      },
      on: {
        init: function () {
          if (this.slides.length <= 1) {
            this.el.classList.add('one_item');
          }
        }
      }
    });
    const numberOfSlides = document.querySelectorAll('.vehicle-uber-swiper .swiper-slide').length;
    const swiper1_1 = new Swiper('.vehicle-uber-swiper',{
      slidesPerView: 1,
      spaceBetween: 15,
      observer: true,
      observeParents: true,
      loop:numberOfSlides === 1 ? false:true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      on: {
        init: function () {
          if (this.slides.length <= 1) {
            this.el.classList.add('one_item')
          }
        }
      }
    });

    const swiper1_2 = new Swiper('.benefist-uber-swiper', {
      slidesPerView: 1,
      spaceBetween: 15,
      observer: true,
      observeParents: true,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      }
    });
    const swiper2 = new Swiper('.features-swiper', {
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      }
    });

    const swiper3 = new Swiper(".benefits-swiper", {
      spaceBetween: 20,
      slidesPerView: 4,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    });

    const swiper4 = new Swiper(".home-header-swiper", {
      slidesPerView: 1,
      loop: true,
      centeredSlides: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".home-header-swiper .swiper-button-next",
        prevEl: ".home-header-swiper .swiper-button-prev",
      },
    });

    let next_uber_car = document.querySelectorAll('.next_uber_car');
    for (let i = 0; i <  next_uber_car.length; i++) {
      next_uber_car[i].addEventListener('click', function (e) {
        swiper1_1.slideNext()
      });
    }
    let next_benefist_page = document.querySelectorAll('.next_benefist_page');
    for (let i = 0; i <  next_benefist_page.length; i++) {
      next_benefist_page[i].addEventListener('click', function (e) {
        swiper1_2.slideNext()
      });
    }
  }

  animateHeader(){
    var doc = document.documentElement;
    var w = window;

    var prevScroll = w.scrollY || doc.scrollTop;
    var curScroll;
    var toggleHeader;
    var direction = 0;
    var prevDirection = 0;

    var header = document.getElementById('header');
    var header_uber = document.getElementById('header-uber');
    if ( header || header_uber) {
      var checkScroll = function() {

        /*
        ** Find the direction of scroll
        ** 0 - initial, 1 - up, 2 - down
        */

        curScroll = w.scrollY || doc.scrollTop;

        if (curScroll > prevScroll) {
          //scrolled up
          direction = 2;
        }
        else if (curScroll < prevScroll) {
          //scrolled down
          direction = 1;
        }
        const backToTopButton = document.querySelector(".scroll-top-button");
        if(backToTopButton) {
          if(curScroll > 500) {
            backToTopButton.classList.remove("hidden");
          } else {
            backToTopButton.classList.add("hidden");
          }
        }
        prevScroll = curScroll;
      };
      if ( header ) {
        if (direction !== prevDirection) {
          toggleHeader(direction, curScroll);
        }
        toggleHeader = function(direction, curScroll) {
          if (direction === 2 && curScroll > 60) {
            
            //replace 60 with the height of your header in px
            
            header.classList.add('hide');
            prevDirection = direction;
          }
          else if (direction === 1) {
            header.classList.remove('hide');
            prevDirection = direction;
          }
        };
      }
    }
    window.addEventListener('scroll', checkScroll);
  }

  animateUber(){
    // Menú - animacion nav
    //Obtenemos los elementos del menu por medio de su clase
    let menuScroll = document.querySelectorAll('.scroll_anchor');
    //Agregamos un evento onclick a cada elmento del menú
    for (let i = 0; i < menuScroll.length; i++) {
      menuScroll[i].addEventListener('click', function (e) {
        e.preventDefault();
        //obtenemos el elemento al cual queremos desplazarnos
        let getNameObjetivo = `${this.getAttribute('href')}`
        //obtenemos la altura con respecto a la pagina del elemento al q queremos desplazarnos
        let getPositionObjetivo = document.querySelector(getNameObjetivo).offsetTop;
        if (getPositionObjetivo != 0) {
          scroll({
            top: getPositionObjetivo,
            behavior: "smooth"
          });
          return
        }
        scroll({
          top: 0,
          behavior: "smooth"
        });
      })
    }
    // Detectamos cuando el usuario desplace la pantalla
    window.onscroll = function () {
      // Obtenemos la posicion del scroll en pantall
      let scroller = document.documentElement.scrollTop || document.body.scrollTop;
      let header = document.querySelector('.header');
      if (scroller < 50) {
        header.classList.remove("hide");
      } else {
        header.classList.add("hide");
      }
      if ( document.getElementById( "intro-seccion" )) {
        let secciones = [document.querySelector(".uber-landing-header").offsetTop, document.querySelector(".plans-seccion").offsetTop, document.querySelector(".uber-benefits-section").offsetTop, document.querySelector(".location-seccion").offsetTop, document.querySelector("#uber-footer").offsetTop, document.body.offsetHeight];

        if (scroller > secciones[0] - 10 && scroller < secciones[1] - 65) {
          document.querySelector('.link-intro').classList.add("active");
        } else {
          document.querySelector('.link-intro').classList.remove("active");
        }
        if (scroller > secciones[1] - 65 && scroller < secciones[2] - 65) {
          document.querySelector('.link-plans').classList.add("active");
        } else {
          document.querySelector('.link-plans').classList.remove("active");
        }
        if (scroller > secciones[2] - 65 && scroller < secciones[3] - 65) {
          document.querySelector('.link-uberBenefits').classList.add("active");
        } else {
          document.querySelector(".link-uberBenefits").classList.remove("active");
        }
        if (scroller > secciones[3] - 65 && scroller < secciones[4] - 65) {
          document.querySelector('.link-location').classList.add("active");
        } else {
          document.querySelector(".link-location").classList.remove("active");
        }
        if (scroller > secciones[4] - 65 && scroller < secciones[5]) {
          document.querySelector('.link-uberfooter').classList.add("active");
        } else {
          document.querySelector(".link-uberfooter").classList.remove("active");
        }
      }
    }
  }

  animeteTags(){
    let item_tag = document.querySelectorAll('.item_tag');
    let item_question = document.querySelectorAll('.item_question');
    for (let i = 0; i < item_tag.length; i++) {
      item_tag[i].addEventListener('click', function (e) {

        for (let j = 0; j < item_tag.length; j++) {
          item_tag[j].classList.remove("active")
        }

        for (let k = 0; k < item_question.length; k++) {
          item_question[k].classList.remove("active")
        }

        let dataAttribute = this.getAttribute('data-tag');
        for (let l = 0; l < item_question.length; l++) {
          if(item_question[l].classList.contains(dataAttribute)){
            item_question[l].classList.add("active");
          }
        }

        this.classList.add("active")
      });
    }
  }
}
