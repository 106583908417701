import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.imageField = this.element.querySelector('input[type="file"]')
    this.validationCheck = this.element.querySelector('.validation-check')
    this.submitButton = this.element.querySelector('input[type="submit"]')
    if(!this.submitButton) this.submitButton = this.element.querySelector('button[type="submit"]')
    this.bindElementEvent()
  }

  bindElementEvent() {
    this.validationCheck.addEventListener('change', event => { this.validateForm(event) })
    this.imageField.addEventListener('change', event=> { this.validateForm(event) })
  }

  validateForm(event) {
    if(this.videoCompleted() && this.validationCheckCompleted()) {
      this.submitButton.disabled = false
    } else {
      this.submitButton.disabled = true
    }
  }

  videoCompleted() {
    return this.element.querySelector('video') != undefined
  }

  validationCheckCompleted() {
    return this.validationCheck.checked
  }
}
