import { DirectUpload } from "@rails/activestorage"
import  FeedbackManager from "./upload_feedback_manager"
import { destroy } from "@rails/request.js"
import CSRFTokenValidator from "../../../../utils/csrf_token_validator"

export default class {
  #element
  #fileInput
  #feedbackManager
  #csrfTokenValidator

  constructor(element, fileInput) {
    this.#element = element
    this.#fileInput = fileInput
    this.#feedbackManager = new FeedbackManager(this.#element.parentNode)
    this.#csrfTokenValidator = new CSRFTokenValidator()
  }

  uploadImage(file) {
    if (this.#csrfTokenValidator.isValid()) {
      this.#feedbackManager.appendSpinner()
      const url = this.#fileInput.dataset.directUploadUrl
      const upload = new DirectUpload(file, url, this)
      upload.create((error, blob) => {
        if (error) {
          console.log(error)
        } else {
          this.#appendFileIdToForm(blob.signed_id)
          this.#element.setAttribute('data-blob-id', blob.signed_id)
          this.#fileInput.value = null
          this.#feedbackManager.removeSpinner()
        }
      })
    } else {
      if (confirm("Tu sesión ha expirado y no se puede guardar la imagen.\nPor favor. Vuelve a iniciar sesión"))
        window.location.href = '/users/sign_in'
    }
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress',
      event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
    this.#feedbackManager.updateProgress((event.loaded * 100 / event.total).toFixed(1))
  }

  async deleteImage() {
    if(this.#element.dataset.filePersisted === 'true') {
    } else {
      const blobId = this.#element.dataset.blobId
      const destroyPath = `/storage/${blobId}`
      const response = await destroy(destroyPath, {})
      if(response.ok) {
        this.#element.querySelector(`input[name='${this.#fileInput.name}'][type=hidden]`)?.remove()
        this.#element.removeAttribute('data-blob-id')
      }
    }
  }

  #appendFileIdToForm(blobId) {
    const hiddenField = document.createElement('input')
    hiddenField.setAttribute("type", "hidden")
    hiddenField.setAttribute("value", blobId)
    hiddenField.name = this.#fileInput.name
    this.#element.appendChild(hiddenField)
  }
}
