import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['label']
  static values = {
    seconds: { type: Number, default: 60 },
    refreshInterval: { type: Number, default: 1000 },
    notify: { type: Boolean, default: false }
  }

  connect() {
    this.totalTime = this.secondsValue
    this.minutesCount = (this.totalTime / 60) - 1
    this.secondsCount = Number(60)
    this.renderTime(this.minutesCount+1, 0)
    this.runTime()
  }

  runTime() {
    let disabledInterval = setInterval(() => {
      this.secondsCount -= 1
      this.renderTime(this.minutesCount, this.secondsCount)
      if(this.secondsCount <= 0 && this.minutesCount <= 0){
        this.renderTime(0, 0)
        clearInterval(disabledInterval)
        this.launchModal()
      }
      if(this.secondsCount == 0 && this.minutesCount >= 0) {
        this.secondsCount  = Number(60)
        this.minutesCount -= 1
      }
    }, this.refreshIntervalValue)
  }

  renderTime(minutes, seconds) {
    this.labelTarget.textContent = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
  }

  launchModal() {
    if(this.notifyValue) {
      const event = new CustomEvent("launch-modal")
      window.dispatchEvent(event)
    }
  }
}
