import CarsSelectionController from './cars_selection_controller'
import Toast from '../utils/toast'
import PricingLocator from  '../utils/pricing_locator'

export default class QuotationsCarsTabController extends CarsSelectionController {
  connect() {
    const guaranteeDepositFields = document.querySelectorAll('.guarantee-deposit')
    const pricingLocator = new PricingLocator(guaranteeDepositFields,
                                              this.element, this.application)
  }
}
