import { Controller } from "@hotwired/stimulus"
import { Collapse } from "bootstrap"

export default class extends Controller {
  static targets = ['questionsContainer']

  addQuestions() {
    const selectedQuestions = this.#findSelectedQuestions()
    this.#notifySelectedQuestions(selectedQuestions)
    this.#closeQuestionsMenu()
  }

  #findSelectedQuestions() {
    const selectedQuestions = this.questionsContainerTarget.querySelectorAll('input[type="checkbox"]:checked')
    return selectedQuestions
  }

  #notifySelectedQuestions(inputs) {
    const eventTarget = this.element.parentNode.querySelector('.questions-list ul')
    const questionsInfo = Array.from(inputs).map(element => {
      return [element.value, element.dataset.content]
    })
    const customEvent = new CustomEvent('questionnaire:selectedQuestions', {
      detail: { questionsInfo: questionsInfo },
      bubbles: true
    })
    eventTarget.dispatchEvent(customEvent);
  }

  #closeQuestionsMenu() {
    const questionsMenu = this.element.querySelector(`#${this.element.dataset.targetId}`)
    if(!questionsMenu) return

    const collapsable = Collapse.getInstance(questionsMenu)
    if(collapsable) collapsable.toggle()
  }
}
