import { Controller } from "@hotwired/stimulus"
import Swiper, { Navigation, Pagination } from 'swiper';

export default class extends Controller {

  connect() {
    const swiper = new Swiper('.pills-swiper', {
      speed: 400,
      loop: false,
      spaceBetween: 5,
      slidesPerView: 2.5,
      breakpoints: {
        768: {
          slidesPerView: 4.1
        }
      }
    })
  }
}
