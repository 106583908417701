export default class addFields {
  constructor() {
    this.links = document.querySelectorAll('.add_fields')
    this.iterateLinks()
  }

  iterateLinks() {
    if (this.links.length === 0) return

    this.links.forEach(link => {
      link.addEventListener('click', e => {
        this.handleClick(link, e)
      })
    })
  }

  handleClick(link, e) {
    if(!link || !e) return
    e.preventDefault()

    const nested_fields = this.getNestedFields(link)
    const time = new Date().getTime()
    const linkId = link.dataset.id
    const count = nested_fields.length
    // Create a new regular expression needed to find any
    // instance of the `new_object.object_id` used in the
    // fields data attribute if there's a value in `linkId`.
    const regexp = linkId ? new RegExp(linkId, 'g') : null
    // Replace all instances of the `new_object.object_id` with `time`,
    // and save markup into a variable if there's a value in `regexp`.
    //let newFields = regexp ? link.dataset.fields.replace(regexp, time) : null
    const fieldsString = link.dataset.fields
    let newFields = fieldsString.replace(regexp, time).replace('{{count}}', `${parseInt(count) + 1}`)
    if(count == 0) {
      newFields =  newFields.replace(/delete-button/, 'delete-button d-none')
    }

    // Notify event
    const addFieldsEvent = new CustomEvent('form:changed', {});
    document.body.dispatchEvent(addFieldsEvent)

    // Add the new markup to the form
    link.insertAdjacentHTML('beforebegin', newFields)

    // Handle second level nested forms
    if(nested_fields.length > 0) {
      last_field = nested_fields[nested_fields.length - 1]
      inner_link = last_field.querySelector('.add_fields')
      if(inner_link) {
        inner_link.addEventListener('click', e => {
          this.handleClick(inner_link, e)
        })
      }
    }
  }

  getNestedFields(link) {
    const parent = link.parentNode
    const nested_fields = Array.from(parent.querySelectorAll('.nested-fields')).filter(nf => {
      if( nf.style.display != 'none') {
        return nf
      }
    })
    return nested_fields
  }
}
