import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.backdrop = document.querySelector('.popup-backdrop')
    this.backdrop.classList.add('active')
    this.container = this.element.closest('.container-info')
    this.container.classList.add('active')
  }

  disconnect() {
    this.backdrop.classList.remove('active')
    this.container.classList.remove('active')
  }
}
