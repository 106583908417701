import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    cancellor: String
  }

  connect() {
    this.bindCancelor()
    this.cancelableInputs = Array.from(this.element.querySelectorAll(`input:not([value='${this.cancellorValue}'])`))
    this.cancelableInputs.forEach(input => input.addEventListener('change', () => this.uncheckCancellor()))
  }

  bindCancelor() {
    this.cancellor = this.element.querySelector(`input[value='${this.cancellorValue}']`)
    this.cancellor.addEventListener('change', (e) => this.cancelSelected())
  }

  cancelSelected() {
    this.cancelableInputs.forEach(input =>{
      if(input.checked) {
        const label = document.querySelector(`label[for='${input.id}'`)
        label.click()
      }
    })
    this.cancellor.checked = true
  }

  uncheckCancellor() {
    this.cancellor.checked = false
  }
}
