import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage"
import { destroy } from '@rails/request.js'

export default class extends Controller {
  static targets = ['uploadButton', 'destroyButton']

  connect() {
    this.videoDropArea = this.element.querySelector('.video-wrapper')
    this.fileInput = this.element.querySelector('input[type="file"]')
    this.uploadButtonTarget.addEventListener('click', event => { this.fileInput.click() })
    this.fileInput.addEventListener('change', event => { this.handleVideo(event) })
    this.destroyButtonTarget.addEventListener('click', event => { this.deleteVideo(event) })
  }

  handleVideo(event) {
    if(this.fileInput.files[0] == null) return
    if(!this.isAVideo()){
      alert('El archivo no es un video')
      return
    }

    const temporalUrl = URL.createObjectURL(this.fileInput.files[0])
    const video = this.createVideoElement(temporalUrl)
    this.videoDropArea.appendChild(video);
  }


  isAVideo() {
    const format = this.fileInput.files[0].type.toString().split("/")[0]
    return format == "video"
  }

  deleteVideo(event) {
    const videoTarget = this.element.querySelector('video')
    videoTarget.remove()
  }

  createVideoElement(srcUrl) {
    const video = document.createElement('video')
    video.src = srcUrl
    video.autoplay = false
    video.controls = true
    video.style.width = '100%'
    return video
  }
}
