import { Controller } from "@hotwired/stimulus"
import { newElement, truncateString } from '../helpers'

export default class extends Controller {
  connect() {
    this.generateLayers()
    this.loadOptions()
    this.setupEventListeners()
  }

  generateLayers() {
    // Hides the actual select and create another one with a custom design
    this.multiselectContainer = newElement('div', { class: 'multiselect-dropdown' })
    this.element.style.display = 'none'
    this.element.parentNode.insertBefore(this.multiselectContainer, this.element.nextSibling)
    this.dropdownListWrapper = newElement('div', { class: 'multiselect-dropdown-list-wrapper' })
    this.dropdownList = newElement('div', { class: 'multiselect-dropdown-list' })
    this.search = newElement('input', { class: 'multiselect-dropdown-search',
                                        placeholder: 'Buscar' })
    this.dropdownListWrapper.appendChild(this.search)
    this.multiselectContainer.appendChild(this.dropdownListWrapper)
    this.dropdownListWrapper.appendChild(this.dropdownList)
  }

  loadOptions() {
    Array.from(this.element.options).forEach((option) => {
      const optionElement = newElement('div', { class: option.selected ? 'checked' : '',
                                                srcElement: option, id: `checkbox-${option.value}` })
      const optionCheckbox = newElement('input', { type: 'checkbox', checked: option.selected })
      optionElement.appendChild(optionCheckbox)
      optionElement.appendChild(newElement('label', { text: truncateString(option.text, 35) }))

      optionElement.addEventListener('click', () => {
        optionElement.classList.toggle('checked')
        optionElement.querySelector('input').checked = !optionElement.querySelector('input')
                                                                     .checked
        optionElement.srcElement.selected = !optionElement.srcElement.selected
        this.element.dispatchEvent(new Event('change'))
      })
      optionCheckbox.addEventListener('click', () => {
        optionCheckbox.checked = !optionCheckbox.checked
      })
      option.optionElement = optionElement
      this.dropdownList.appendChild(optionElement)
    })
    this.multiselectContainer.dropdownListWrapper = this.dropdownListWrapper

    this.refreshContainer()
  }

  refreshContainer() {
    // Deletes selected options list
    this.multiselectContainer.querySelectorAll('span.optext, span.select-placeholder')
        .forEach((placeholder) => { this.multiselectContainer.removeChild(placeholder) })

    // Updates checked options if change comes from external events
    Array.from(this.element.options).forEach(option => {
      const optionTarget = this.dropdownList.querySelector(`#checkbox-${option.value}`)
      const checkTarget = optionTarget.querySelector('input')
      checkTarget.checked = option.selected
      if(!option.selected && optionTarget.classList.contains('checked'))  optionTarget.classList.remove('checked')
    })

    // Generates selected options list inside input
    const selectedOptions = Array.from(this.element.selectedOptions)
    selectedOptions.map((option) => {
      const span = newElement('span', { class: 'optext', text: truncateString(option.text,  20), srcElement: option })
      span.appendChild(
        newElement('span', {
          class: 'optdel',
          text: '⤫',
          title: 'Eliminar',
          onclick: (event) => {
            span.srcElement.optionElement.dispatchEvent(new Event('click'))
            this.refreshContainer()
            event.stopPropagation()
          }
        })
      )
      this.multiselectContainer.appendChild(span)
    })
    // Sets placeholder if none selected
    if (selectedOptions?.length === 0) {
      this.multiselectContainer.appendChild(
        newElement('span', {
          class: 'select-placeholder',
          text: this.element.attributes?.placeholder?.value ?? 'Selectiona una opción'
        })
      )
    }
  }

  setupEventListeners() {
    // Searching functionality
    this.search.addEventListener('input', event => {
      this.dropdownList.querySelectorAll(':scope div:not(.multiselect-dropdown-all-selector)')
          .forEach((div) => {
            const innerText = div.querySelector('label').innerText.toLowerCase()
            const anyMatch  = innerText.includes(this.search.value.toLowerCase())
            div.style.display = anyMatch ? 'flex' : 'none'
          })
    })

    this.multiselectContainer.addEventListener('click', () => {
      this.multiselectContainer.dropdownListWrapper.style.display = 'block'
      this.search.focus()
      this.search.select()
    })

    // Closes the options dropdown when click any other element
    document.addEventListener('click', event => {
      if(this.multiselectContainer.contains(event.target)) return

      this.dropdownListWrapper.style.display = 'none'
      this.refreshContainer()
    })
  }
}
