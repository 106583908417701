import { Controller } from "@hotwired/stimulus"
import { dataURLtoBlob } from '../helpers'

export default class extends Controller {
  static targets = ['link']

  connect() {
    this.carSection = this.element.querySelector('.cars-section')
    this.mainContainer = document.querySelector('.steps-container')
    this.form = this.linkTarget.parentNode
    this.submited = false
    this.bindForm()
    const threshold = window.isMobile() ? 300 : 250
    let options = {
      top: threshold,
      left: 0,
      behavior: 'smooth'
    }
    window.scroll(options)
  }

  bindForm() {
    this.form.addEventListener('submit', (event) => {
      this.launchAnimation(event)
      const transition = setInterval(() => {
        if(!this.submited) {
          this.form.requestSubmit()
          this.submited = true
        }
        clearInterval(transition)
      }, 500)
    })
  }

  launchAnimation(event) {
    const applicationArea = document.querySelector('.application-area')
    const applicationSection = document.querySelector('.application-section')
    if(applicationSection.classList.contains('application-section-out')) return
    if(this.carSection.classList.contains('animate-fade-out')) return

    applicationSection.classList.remove('application-section-in')
    applicationArea.classList.add('application-area-out')
    applicationSection.classList.add('application-section-out')
    this.carSection.classList.remove('animate-fade-in')
    this.carSection.classList.add('animate-fade-out')
    this.mainContainer.classList.remove('application-open')
    this.mainContainer.classList.add('cars-open')
    event.preventDefault()
    event.stopPropagation()
  }
}
