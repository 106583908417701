import { Controller } from "@hotwired/stimulus"
import FieldsCreator from './shared/issues/fields_creator'

export default class extends Controller {
  renderIssue(event) {
    const targetDataset = event.currentTarget.dataset

    if (!event.currentTarget.checked) {
      document.querySelector(`#container-${targetDataset.fieldIndex}`)?.remove()
    } else {
      const creator = new FieldsCreator({
        sectionId: targetDataset.fieldIndex,
        modelName: targetDataset.fieldModel,
        currentUserId: targetDataset.currentUserId,
        issuableId: targetDataset.issuableId,
        issuableType: targetDataset.issuableType,
        revisionNumber: targetDataset.revisionNumber
      })
      this.#addFormFields(targetDataset.target, creator.getIssuableForm())
    }
  }

  #addFormFields(targetId, section) {
    const fieldsContainer = document.getElementById(targetId)
    fieldsContainer.appendChild(section)
  }
}
