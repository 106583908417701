import { Controller } from "stimulus"
import { Modal } from 'bootstrap'

export default class NotificationController extends Controller {
  static values = {
    url: String,
    closingTime: { type: Number, default: 4000 },
    instantLaunch: { type: Boolean, default: true }
  }

  connect() {
    this.modal = new Modal(document.getElementById('confirmationModal'))
    if(this.instantLaunchValue) {
      this.showModal()
    }
  }

  showModal() {
    this.modal.show()

    setTimeout(() => {
      this.modal.hide()
      this.redirect()
    }, this.closingTimeValue)
  }

  redirect() {
    if(this.urlValue)
      window.location.replace(this.urlValue)
  }
}
