import BaseCreator from './base_creator'
import { promptForSelect } from "../../../helpers"

// El objetivo de esta clase es crear los campos necesarios en un formulario
// para agregar un objeto del tipo comentable, con esto se podran crear dinamicamente
// los campos para agrear un comentario
export default class extends BaseCreator {
  #sectionId
  #modelName
  #currentUserId

  constructor(options) {
    super()
    this.#sectionId = options.sectionId
    this.#modelName = options.modelName
    this.#currentUserId = options.currentUserId
  }

  getCommentableFormGroup() {
    const mainContainer = this.prepareContainer()
    return mainContainer
  }

  prepareContainer() {
    const fieldsContainer = document.createElement('div')
    fieldsContainer.classList.add('form-group', 'issuable-form', 'pt-3')
    fieldsContainer.setAttribute('id', `commentable-${this.#sectionId}`)
    fieldsContainer.appendChild(this.createUserId)
    fieldsContainer.appendChild(this.createCommentTitle())
    fieldsContainer.appendChild(document.createElement('hr'))
    fieldsContainer.appendChild(this.createCommentMessage())

    return fieldsContainer
  }

  createCommentTitle() {
    const commentTitleSelect = this.#createIssueSelector()
    const commentLabel = this.createLabel('Categoría de incidencia del documento',
                                           commentTitleSelect.id)
    const commentTitle = document.createElement('div')
    commentLabel.classList.add('d-inlineblock', 'col-6')
    commentTitleSelect.classList.add('col-6', 'w-50')
    commentTitleSelect.setAttribute('data-validable', true)
    commentTitleSelect.setAttribute('data-validable-type', 'data')
    commentTitleSelect.setAttribute('data-validations', 'presence')
    commentTitle.classList.add('row', 'p-0', 'm-0', 'd-flex', 'justify-content-between',
                               'align-items-center')
    commentTitle.appendChild(commentLabel)
    commentTitle.appendChild(commentTitleSelect)

    return commentTitle
  }

  createCommentMessage() {
    const messageTextArea = document.createElement('textarea')
    const commentMessage = document.createElement('div')
    messageTextArea.classList.add('form-control', 'w-100')
    messageTextArea.rows = 3
    messageTextArea.name = `${this.#modelName}[${this.#sectionId}][comments_attributes][0][message]`
    messageTextArea.setAttribute('data-validable', true)
    messageTextArea.setAttribute('data-validable-type', 'data')
    messageTextArea.setAttribute('data-validations', 'presence')
    commentMessage.classList.add('col-12', 'p-0')
    commentMessage.appendChild(messageTextArea)

    return commentMessage
  }

  #createIssueSelector() {
    const options = ['Falta foto de la fachada del domicilio',
      'Falta foto del prospecto con su identificación',
      'Falta foto de la cochera o espacio para el resguardo',
      'Foto ilegible del prospecto con su identificación']
    const select = document.createElement('select')
    select.name = `${this.#modelName}[${this.#sectionId}][comments_attributes][0][title]`
    select.id = `${this.#modelName}_${this.#sectionId}_comments_attribute_0_title`
    select.classList.add('form-select')
    select.appendChild(promptForSelect())
    options.forEach(option => {
      const optElement = document.createElement('option')
      optElement.value = option
      optElement.text = option
      select.appendChild(optElement)
    })

    return select
  }


  get createUserId() {
    return this.createHiddenField(
      `${this.#modelName}[${this.#sectionId}][comments_attributes][0][user_id]`,
      this.#currentUserId
    )
  }
}
