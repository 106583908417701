export default class {
  #field
  #validations

  constructor(field) {
    this.#field = field
    this.#validations = { presence: this.presenceValidate }
  }

  validate() {
    const results = this.#field.dataset.validations.split(',')?.map(validation => {
      const validator = this.#validations[validation].bind(this)
      return validator()
    })
    return !results.includes(false)
  }

  presenceValidate() {
    return this.#field.value.trim() !== ''
  }
}
