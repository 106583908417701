import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener('input', (event) => {
      let target = event.currentTarget
      let value = target.value
      target.value = this.formatRating(value)
    })
  }

  formatRating(str) {
    if(str.search(/\./) > 0) {
      return str.replace(/\.(\d{1,2}).*$/, '.$1');
    } else {
      return str.substring(0, 1)
    }
  }
}
