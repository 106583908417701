import ImageValidation from './image_validation'
import DataValidation from './data_validation'

export default class {
  #validationStrategies
  #field

  constructor(field) {
    this.#field = field
    this.#validationStrategies = { image: ImageValidation, data: DataValidation }
  }

  isValid() {
    const validator = new this.#validationStrategies[this.inputType](this.#field)
    return validator.validate()
  }

  get inputType() {
    return this.#field.dataset.validableType
  }
}
