import { Controller } from "@hotwired/stimulus"
import ModalBuilder from '../utils/modal_builder'

export default class extends Controller {
  #modalBuilder
  #modalBody

  static values = {
    title: String,
    destroyPath: String,
    message: String,
    start: String,
    end: String
  }

  connect() {
    this.#modalBody = this.#buildModalBody()
    this.#modalBuilder = new ModalBuilder(this.titleValue, this.#modalBody).getBootstrapModal()
  }

  showModal() {
    this.#modalBuilder.show()
  }

  #buildModalBody() {
    const modalBody = document.createElement('div')
    modalBody.classList.add('d-flex', 'flex-column', 'justify-content-center', 'align-items-center')
    modalBody.appendChild(this.#buildContent())
    modalBody.appendChild(this.#buildDestroyButton())
    return modalBody.outerHTML
  }

  #buildContent() {
    const container = document.createElement('div')
    const message = document.createElement('p')
    const date = document.createElement('p')
    message.textContent = this.messageValue
    message.classList.add('dark-brown', 'fs-16', 'mb-1')
    let dateContent = `de <span class="p-fw-bold fs-14">${this.startValue}</span> a `
    dateContent += `<span class="p-fw-bold fs-14">${this.endValue}</span>`
    date.innerHTML = dateContent
    container.appendChild(message)
    container.appendChild(date)
    return container
  }

  #buildDestroyButton() {
    const button = document.createElement('a')
    button.textContent = 'Cancelar'
    button.classList.add('button', 'red')
    button.setAttribute('data-turbo-method', 'delete')
    button.href = this.destroyPathValue
    return button
  }
}
