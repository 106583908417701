import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules'

export default class extends Controller {

  connect() {
    Swiper.use([Navigation, Pagination]);
    const swiper1 = new Swiper('.slide-listas-swiper', {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },   
      pagination: {
          el: '.swiper-pagination',
          type: "fraction",
      }
    });

    let buttonProfileUser = document.querySelector(".button-profile");
    let submenuUser = document.querySelector(".sidebar-bottom--submenu");
   
    buttonProfileUser.addEventListener('click', function () {
      submenuUser.classList.toggle("active")
    })
    submenuUser.onmouseleave = function(){
      this.classList.remove("active")
    }

    let menuAccordion = document.querySelectorAll('.sidebar-body .button-control');
    let collapseContainer = document.querySelectorAll('.sidebar-body .collapse-container');
    for (let i = 0; i < menuAccordion.length; i++) {
      menuAccordion[i].addEventListener('click', function (e) {

        for (let j = 0; j < collapseContainer.length; j++) {
          collapseContainer[j].classList.remove("active")
        }

        for (let k = 0; k < menuAccordion.length; k++) {
          if (menuAccordion[k].classList.contains('button-active')) {
            menuAccordion[k].classList.add("button-normal")
            menuAccordion[k].classList.remove("button-active")
          }
          if (menuAccordion[k].classList.contains('button-active--icon')) {
            menuAccordion[k].classList.add("button-normal--icon")
            menuAccordion[k].classList.remove("button-active--icon")
          }
        }

        let getControlName = `${this.getAttribute('data-button-control')}`
        let collapseName = document.querySelector(`#${getControlName}`);
        collapseName.classList.add("active")

        this.classList.remove("button-normal")
        this.classList.remove("button-normal--icon")
        this.classList.add("button-active")
        this.classList.add("button-active--icon")
      })
    }

    let subMenuAccordion = document.querySelectorAll('.collapse-container .button');
    for (let i = 0; i < subMenuAccordion.length; i++) {
      subMenuAccordion[i].addEventListener('click', function (e) {
        for (let k = 0; k < subMenuAccordion.length; k++) {
          subMenuAccordion[k].classList.add("button-normal")
          subMenuAccordion[k].classList.remove("button-active")
        }
        this.classList.remove("button-normal")
        this.classList.add("button-active")
      })
    }

    let sidebar = document.querySelector(".sidebar")
    let main_container = document.querySelector(".main-container")
    let btn_expand_menu = document.querySelector(".btn-expand-menu")
    var value_btn_expand_menu=false;
    btn_expand_menu.addEventListener('click', function () {
      value_btn_expand_menu = value_btn_expand_menu !== true;
    });
    

    const res = function (){
      let  widthScreen = window.innerWidth;
      if ( widthScreen > 768){
        sidebar.onmouseenter= function(){
          if (value_btn_expand_menu==false){
          sidebar.classList.add("active")
          main_container.classList.add("active")
          }
        };
        sidebar.onmouseleave = function(){
          if (value_btn_expand_menu==false){
          sidebar.classList.remove("active")
          main_container.classList.remove("active")
          }
        }; 
      } else{
        sidebar.onmouseenter = null;
        sidebar.onmouseleave = null;
      }
    }
    res()
    window.addEventListener("resize", function(){
      res()
   });

    let button_sub_menu =document.querySelectorAll('.box-sub-menu .button-sub-menu');
    for (let i = 0; i < button_sub_menu.length; i++) {
      button_sub_menu[i].addEventListener('click', function (e) {
        this.classList.add("active")
        let paretnElement = this.parentNode.querySelector(".sub-menu-doctos")
        paretnElement.classList.toggle("active")
        paretnElement.onmouseleave = function(){
          this.classList.remove("active")
          button_sub_menu[i].classList.remove("active")
        };
      })
    }
    let serch_container=document.querySelectorAll(".serch-container")
    let close_filters=document.querySelectorAll(".serch-container .close-filters")
    let open_filters=document.querySelectorAll(".serch-mobile-container .open-filters")
    
    for (let i = 0; i < close_filters.length; i++) {
      close_filters[i].addEventListener('click', function (e) {
        serch_container[i].classList.toggle("active")
      })
    }
    
    for (let i = 0; i < open_filters.length; i++) {
      open_filters[i].addEventListener('click', function (e) {
        serch_container[i].classList.toggle("active")
      })
    }
    let active_sidebar = document.querySelector(".active-sidebar")
    active_sidebar.addEventListener('click', function (e) {
      sidebar.classList.toggle("active")
    });
    let close_sidebar = document.querySelector(".close-sidebar")
    close_sidebar.addEventListener('click', function (e) {
      sidebar.classList.toggle("active")
    });

    let docto_select_table= document.getElementById("docto_select_table")
    let box_container_documentos = document.querySelectorAll(".box-container-documentos")
    docto_select_table?.addEventListener('change', (event) => {
      let table_select = docto_select_table.value
      for (var i = 0; i< box_container_documentos.length; i++) {
        box_container_documentos[i].classList.remove("active");
     }
     document.querySelector("."+table_select).classList.add("active")
    });

    let active_dicataminar = document.querySelectorAll(".botonera-dictaminar .button")
    for (let i = 0; i < active_dicataminar.length; i++) {
      active_dicataminar[i].addEventListener('click', function (e) {
        for (var j = 0; j< active_dicataminar.length; j++) {
          active_dicataminar[j].classList.remove("active");
        }
        this.classList.add("active");
      })
    }
  }
}
