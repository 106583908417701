import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    this.deleteButton = this.element.querySelector('[data-button="generate"]')
    this.deleteButton.addEventListener('click', event =>  {
      this.element.querySelectorAll('.pricing-item').forEach(element => {
        element.classList.remove('d-none')
      })
    })
  }
}
