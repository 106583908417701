export default class {
  #container
  #previewFrame

  constructor(container, previewFrameClass) {
    this.#container = container
    this.#previewFrame = this.#container.querySelector(previewFrameClass)
  }

  isCompleted() {
    return this.#hasCompleteClass() && this.#previewFrame.dataset?.imageUrl !== ''
  }

  renderImage() {
    this.#previewFrame.style.backgroundImage = `url(${this.#previewFrame.dataset.imageUrl})`
  }

  removeImage() {
    this.#previewFrame.style.backgroundImage = ''
  }

  toComplete(url, fileType) {
    if(fileType === 'image') {
      this.#previewFrame.setAttribute('data-file-url', url)
      this.#previewFrame.setAttribute('data-image-url', url)
    } else {
      this.#previewFrame.setAttribute('data-file-url', url)
      this.#previewFrame.setAttribute('data-image-url', this.#container.dataset.defaultFileImage)
    }
    this.#previewFrame.setAttribute('data-file-type', fileType)
    if(this.#hasCompleteClass()) return

    this.#previewFrame.classList.add('completed')
  }

  toPending() {
    if(!this.#hasCompleteClass()) return

    this.#previewFrame.classList.remove('completed')
  }

  #hasCompleteClass() {
    return this.#previewFrame.classList.contains('completed')
  }
}
