import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener('input', (event) => {
      let target = event.currentTarget
      let value = target.value
      target.value = this.formatNumber(value)
    })
  }

  formatNumber(str) {
    if(str.length == 0)
      return ''

    str = str.replace(',', '')
    return parseInt(str).toLocaleString('en-US')
  }
}
