export default class {
  #element
  #spinner
  #progress

  constructor(element) {
    this.#element = element
    this.#createSpinner()
  }

  appendSpinner() {
    this.#element.appendChild(this.#spinner)
  }

  updateProgress(progress) {
    this.#progress.textContent = `${progress}%`
  }

  removeSpinner() {
    this.#spinner.remove()
  }

  #createSpinner() {
    this.#setupProgress()
    const loader = this.#spinnerLoader()
    const spinner = this.#setupSpinner()
    this.#spinner = this.#setupContainer()
    spinner.appendChild(loader)
    this.#spinner.appendChild(spinner)
  }

  #setupContainer() {
    const container = document.createElement('div')
    container.classList.add('d-flex', 'justify-content-center', 'flex-column', 'align-items-center',
                            'py-3', 'spinner-container')
    container.appendChild(this.#progress)
    return container
  }

  #setupSpinner() {
    const spinner = document.createElement('div')
    spinner.setAttribute('role', 'status')
    spinner.classList.add('spinner-border', 'spinner-border-sm')
    return spinner
  }

  #setupProgress() {
    this.#progress = document.createElement('p')
    this.#progress.classList.add('m-0', 'mb-1')
    this.#progress.textContent = '0%'
  }

  #spinnerLoader() {
    const span = document.createElement('span')
    span.classList.add('visually-hidden')
    span.textContent = 'Loading...'
    return span
  }
}
