import { Controller } from "stimulus"
import { Modal } from 'bootstrap'

export default class extends Controller {
  connect() {
    this.modal = new Modal(this.element)
    this.showModal()
  }

  showModal() {
    this.modal.show()
  }

  dismissAndRedirect(e) {
    e.preventDefault()
    e.stopPropagation()
    this.modal.hide()
    window.location.replace(e.currentTarget.href)
  }
}
