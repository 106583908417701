import { getMetaValue } from "@rails/activestorage/src/helpers"

export default  class {

  isValid() {
    return this.#tokenIsPressent()
  }

  #tokenIsPressent() {
    const csrfToken = getMetaValue("csrf-token")
    return csrfToken !== undefined
  }
}
