export default class {
  static baseName = `questionnaire[questionnaire_pages_attributes]`
  static #questionId
  static #pageId
  static #position
  static #questionText

  static build(questionId, pageId, lastPosition, questionText) {
    this.#questionId = questionId
    this.#pageId = pageId
    this.#position = lastPosition
    this.#questionText = questionText
    return this.#createListItem()
  }

  static #createListItem() {
    const listItem = document.createElement('li')
    listItem.classList.add('question-holder')
    listItem.setAttribute('draggable', 'true')
    listItem.setAttribute('data-index', this.#position)
    listItem.setAttribute('data-questionnaires--questions-area-target', 'question')
    listItem.setAttribute('data-controller', 'questionnaires--nested-question')
    listItem.setAttribute('data-action', 'click->questionnaires--nested-question#delete')
    listItem.appendChild(this.#createQuestionsContainer())
    return listItem
  }

  static #createQuestionsContainer() {
    const container = document.createElement('div')
    container.classList.add('questionnaire-question-container')
    this.#appendDataFields(container)
    container.appendChild(this.#createPositionLabel())
    container.appendChild(this.#createQuestionLabel())
    container.appendChild(this.#createRemoveButton())
    return container
  }

  static #createPositionLabel(){
    const position = document.createElement('div')
    position.classList.add('position')
    position.textContent = this.#position
    return position
  }

  static #createQuestionLabel() {
    const question = document.createElement('div')
    question.classList.add('text')
    question.textContent = this.#questionText
    return question
  }

  static #createRemoveButton() {
    const button = document.createElement('a')
    button.href = 'javascript:void(0)'
    button.classList.add('red-error')
    button.textContent = 'eliminar'
    const container = document.createElement('div')
    container.classList.add('remove-button')
    container.appendChild(button)
    return container
  }

  static #appendDataFields(element) {
    const questionIdField = this.#createInput('question_id', this.#questionId, ['question-id'])
    const positionField = this.#createInput('position', this.#position, ['position-value'])
    const destroyField = this.#createInput('_destroy', false, ['deletion-value'])
    element.appendChild(questionIdField)
    element.appendChild(positionField)
    element.appendChild(destroyField)
  }

  static #createInput (name, value, classes=[]) {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = `${this.baseName}[${this.#pageId}][questionnaire_questions_attributes][${this.#position}][${name}]`
    input.value = value
    classes.forEach(klass => input.classList.add(klass))
    return input
  }
}
