import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener('submit', e => { this.validateForm(e) })
    this.fileElements = Array.from(this.element.querySelectorAll("input[type=file]"))
  }

  validateForm(e) {
    if(!this.allFormAttachedFields()) {
      e.preventDefault()
      e.stopPropagation()
      this.renderInvalidFields()
    }
  }

  allFormAttachedFields() {
    return !this.fileElements.some( input => input.dataset.fileAttached === 'false')
  }

  renderInvalidFields() {
    this.fileElements.forEach( input => {
      if(input.dataset.fileAttached === 'false') {
        let targetId  = input.id.replace('file-attachment', 'picture-frame')
                             .replace('address-image-field', 'picture-frame')
        const target = document.getElementById(targetId)
        const errorLabel = target.parentNode.querySelector('.error-label')
        target.classList.add('upload-area--invalid')
        errorLabel.style.display = 'block'
      }
    })
  }
}
