import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['fileField', 'uploadButton', 'uploadButtonArea',
                    'fileNameLabel', 'deleteFileButton', 'fileArea',
                    'submitButton']

  connect() {
    this.setupInputs()
  }

  setupInputs() {
    this.fileFieldTarget.addEventListener('change', event => {
      event.preventDefault()
      this.handleUpload(this.fileFieldTarget.files[0])
    })
    this.deleteFileButtonTarget.addEventListener('click', event => {
      event.preventDefault()
      this.deleteImage()
    })
  }

  openExplorer(event) {
    event.preventDefault()
    this.fileFieldTarget.click()
  }

  handleUpload(file) {
    if(!file) return

    this.fileAreaTarget.classList.remove('d-none')
    this.fileNameLabelTarget.textContent = file.name
    this.uploadButtonAreaTarget.classList.add('d-none')
    this.validateForm()
  }

  deleteImage() {
    this.fileFieldTarget.value = null
    this.fileAreaTarget.classList.add('d-none')
    this.uploadButtonAreaTarget.classList.remove('d-none')
    this.validateForm()
  }

  validateForm() {
    if(!this.validForm()) {
      this.submitButtonTarget.disabled = true
    } else {
      this.submitButtonTarget.disabled = false
    }

  }

  validForm() {
    return this.fileFieldTarget.files.length > 0
  }
}
