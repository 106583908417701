import CarsSelectionController from './cars_selection_controller'
import PricingLocator from  '../utils/pricing_locator'
import { Modal } from 'bootstrap'

export default class LandingCarsTabController extends CarsSelectionController {
  connect() {
    this.setModal()
    this.filtersVehicleSectio()
    const guaranteeDepositFields = document.querySelectorAll('.guarantee-deposit')
    const pricingLocator = new PricingLocator(guaranteeDepositFields,
                                              this.element, this.application)
  }

  setModal(){
    this.selectionModalTrigger = document.querySelectorAll('.selection-modal-trigger')
    this.closeModalButton = document.querySelector('.car-selection-modal-close')
    this.selectionModalTrigger.forEach(element => {
      element.addEventListener('click', event => {
        const modal = Modal.getInstance(document.getElementById('car-selection-modal'))
        modal.show()
      })
    })
    if(this.closeModalButton) {
      this.closeModalButton.addEventListener('click', event => {
        const modal = Modal.getInstance(document.getElementById('car-selection-modal'))
        modal.hide()
      })
    }
  }
}
