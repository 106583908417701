import { Controller } from "@hotwired/stimulus"
import ImageManager from './utils/image_manager'
import PreviewManager from './utils/preview_manager'
import ActiveStorageManager from './utils/active_starage_manager'

export default class extends Controller {
  static targets = ['fileInput']

  connect() {
    this.previewManager = new PreviewManager(this.element, '.preview')
    this.imageManager = new ImageManager(this.fileInputTarget)
    this.activeStorageManager = new ActiveStorageManager(this.element, this.fileInputTarget)
    this.element.addEventListener('image:content-removed', () => { this.#contentRemoved() })
    this.fileInputTarget.addEventListener('change', _event => { this.#fileUploadHanddle() })
  }

  #contentRemoved() {
    if(this.#isDirectUploadable) this.activeStorageManager.deleteImage()
    this.fileInputTarget.value = '';
    this.fileInputTarget.removeAttribute('data-file-attached')
    this.previewManager.toPending()
  }

  #fileUploadHanddle() {
    this.imageManager.processFile()
    if (!this.imageManager.fileLoaded()) return

    if(this.#isDirectUploadable) this.activeStorageManager.uploadImage(this.imageManager.getFile())
    this.previewManager.toComplete(this.imageManager.getFileUrl(), this.imageManager.fileType())
    this.fileInputTarget.setAttribute('data-file-attached', true)
    this.element.dispatchEvent(new Event('image:content-change'))
  }

  get #isDirectUploadable() {
    return this.element.dataset.directUpload === 'true'
  }
}
