import { Controller } from "@hotwired/stimulus"
import Swiper, { Navigation, Pagination } from 'swiper';

export default class extends Controller {
  connect() {
    const swiper = new Swiper(this.element, {
      spaceBetween: 10,
      slidesPerView: 2,
      pagination: {
        el: '.swiper-pagination'
      }
    })
  }
}
