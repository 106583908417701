import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['page']

  connect() {
    this.pagesContainer = document.getElementById('pages')
    this.pages = Array.from(this.pageTargets)
  }

  sortPages() {
    this.#arrangePages()
    this.pagesContainer.innerHTML = ""
    this.pages.forEach(el => this.pagesContainer.appendChild(el))
  }

  #arrangePages() {
    this.pages.sort((a, b) => {
      const aPosition = a.querySelector('.position-input').value
      const bPosition = b.querySelector('.position-input').value

      return Number(aPosition) - Number(bPosition)
    })
  }
}
