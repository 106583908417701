import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  parse(event){
    // when the arrow keys are pressed
    if (event != undefined && [38,40,37,39].indexOf(event.keyCode) !== -1 ) {
      this.resetPosition()
      return
    }

    let commansBeforeCount = this.commasCount()

    let amount = this.value.replace(/[\D\s_\-]+/g, '').slice(0,-2)

    if(event == undefined)
      amount = this.value.replace(/[\D\s_\-]+/g, '').slice(0,-1)

    if(amount !== '' && !isNaN(amount)){
      amount = parseInt(amount)
      let caret_pos = this.getCaretPosition()
      this.element.value = `${amount.toLocaleString('en')}.00` // caret is moved to the end
      var commansAfterCount = this.commasCount()
      if(commansBeforeCount !== commansAfterCount){
        caret_pos = commansBeforeCount < commansAfterCount ? caret_pos + 1 : caret_pos - 1
      }
      if(event != undefined && event.keyCode !== 8 && isNaN(String.fromCharCode(event.keyCode))){
        this.setCaretPosition(caret_pos - 1)
      } else if(event != undefined) {
        this.setCaretPosition(caret_pos)
      }
    } else {
      this.element.value = '.00'
      this.setCaretPosition(0)
    }
  }

  cursorPosition(){
    this.resetPosition()
  }

  commasCount(){
    let commaMatcher = this.value.match(/,/g)
    return commaMatcher === null ? 0 : commaMatcher.length
  }
  get value() {
    return this.element.value
  }

  resetPosition(){
    const caret_pos = this.getCaretPosition()
    const val_len = this.value.length - 3
    if(caret_pos > val_len){
      this.setCaretPosition(val_len)
    }
  }

  setCaretPosition(caretPos) {
    if(this.element.createTextRange) {
      var range = this.element.createTextRange();
      range.move('character', caretPos);
      range.select();
    }
    else {
      if(this.element.selectionStart) {
          this.element.focus();
          this.element.setSelectionRange(caretPos, caretPos);
      }
      else{
        this.element.focus();
      }
    }
  }

  getCaretPosition() {
    let caretPos = 0; // IE Support
    if (document.selection) {
      this.focus();
      let range = document.selection.createRange();
      range.moveStart('character', -this.value.length);
      caretPos = range.text.length;
    }
    // Firefox support
    else if (this.element.selectionStart || this.element.selectionStart == '0') caretPos = this.element.selectionStart;
    return caretPos
  }

  get amountValue(){
    return parseInt(this.value.replace(/[^\d.]/g, ''))
  }
}
