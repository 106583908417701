import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.selectedDocumentsValue = [];
    this.allDocumentsIds =  Array.from(this.element.querySelectorAll('option')).map(el => el.value)
    this.documentContainer = this.element.querySelector("#document_report_container");
    this.hiddenField = this.element.querySelector("#user_pld_report_user_pld_document_version_ids");

    const initialValues = this.hiddenField.value.split(',');
    if (initialValues.length > 0 && initialValues[0] !== "") {
      this.addInitialDocuments(initialValues, true);
    }
  }

  createDocumentHTML(documentId, documentText, isWrong, clase) {
    const documentClass = isWrong ? "btn-danger" : "btn-info";
    const documentLabel = isWrong ? "Documento incorrecto" : "Nuevo documento";
    return `
      <div class="document-report-item ${documentClass}">
        <div class="document-report-box">
          <div class="icon-coment ${clase}"> </div>
          <p class="mb-0">${documentText}</p>
          <button class="remove-document-btn" data-action="click->pld-documents-report#removeDocument" data-document-id="${documentId}">X</button>
        </div>
        <p class="document-type">${documentLabel}</p>
      </div>
    `;
  }

  addInitialDocuments(initialValues, isWrong) {
    initialValues.forEach((documentId) => {
      const option = this.element.querySelector(`option[value="${documentId}"]`);
      if (option) {
        const documentText = option.text;
        const documentHTML = this.createDocumentHTML(documentId, documentText, isWrong, null);
        this.documentContainer.insertAdjacentHTML("beforeend", documentHTML);
        this.selectedDocumentsValue.push(documentId);
        option.selected = true
      }
    });
  }

  addDocument(event) {
    const selectedOptions = Array.from(this.element.querySelectorAll('option'))
                                 .filter(el => el.selected)
    const selectedIds = selectedOptions.map(el => el.value)
    const removedIds = this.selectedDocumentsValue.filter(id => !selectedIds.includes(id))

    selectedOptions.forEach(option => {
      if(!this.selectedDocumentsValue.includes(option.value)) {
        const documentHTML = this.createDocumentHTML(option.value, option.text, false,
                                                     option.dataset.documentType);
        this.documentContainer.insertAdjacentHTML("beforeend", documentHTML);
      }
    })
    this.selectedDocumentsValue = selectedIds

    removedIds.forEach(id => {
      const targetButton = document.querySelector(`[data-document-id="${id}"]`)?.parentNode
      const target = targetButton?.closest('.document-report-item')
      target?.remove()
    })

    this.updateHiddenField();
  }

  removeDocument(event) {
    const documentId = event.target.dataset.documentId;
    this.selectedDocumentsValue = this.selectedDocumentsValue.filter(id => id !== documentId);
    const doc = event.target.closest(".document-report-item");
    doc.remove();
    const option = this.element.querySelector(`option[value="${documentId}"]`);
    if (option) option.selected = false;
    this.updateHiddenField();
  }

  updateHiddenField() {
    this.hiddenField.value = this.selectedDocumentsValue.join(',');
    this.hiddenField.dispatchEvent(new Event('change'))
  }
}
