import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['trigger']

  connect() {
    const sideBar = document.querySelector('.sidebar')
    const closeButton = document.querySelector('.close-sidebar')

    this.triggerTarget.addEventListener('click', event => { sideBar.classList.toggle('active') })
    closeButton.addEventListener('click', event => { sideBar.classList.toggle('active') })
  }
}
