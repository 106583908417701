import { Validation } from "../validators"
import Validations from "../validators/validations.js.erb"
import { FormValidationController } from "./form_validation_controller"
import { events } from "../utils/events"
export default class RegistrationController extends FormValidationController {
  connect() {
    this.newRecord = this.element.dataset.newrecord
    this.eventForInputType = events
    this.validation = new Validation()
    this.validatableInputs = []
    this.errors = {}
    this.bindInputs()
    this.bindSelects()
    this.bindForm()
  }

  render(target, validation) {
    let validator = validation.find((validator) => !validator.result.valid)
    if (validator) {
      this.renderValidation(target, validator)
    } else {
      const options = Array.from(document.querySelectorAll(`input[name='${target.name}']`))
      if(options.length > 1) {
        options.forEach(input => this.clearValidation(input, validator))
      } else {
        this.clearValidation(target, validator)
      }
    }
  }

  clearValidation(target, validator) {
    target.classList.remove('invalid')

    let help = target.closest('fieldset').querySelector('.error-label')
    help.classList.remove('show')
    help.innerHTML = ''
  }

  renderValidation(target, validator) {
    target.classList.add('invalid')
    let help = target.closest('fieldset').querySelector('.error-label')
    help.classList.add('show')
    help.innerHTML = `${validator.result.message}<br>`
  }
}
