import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener('submit', e => { this.validateForm(e) })
    this.fileElements = Array.from(this.element.querySelectorAll("input[type=file]"))
    this.validationCheck = this.element.querySelector('.validation-check')
    this.validationCheck.addEventListener('change', event => { this.checkUpdated(event) })
  }

  validateForm(e) {
    if(!this.allFormAttachedFields()) {
      e.preventDefault()
      e.stopPropagation()
      this.renderInvalidFields()
    }
  }

  checkUpdated(event) {
    if(this.validationCheck.classList.contains('invalid') && this.validationCheck.checked) {
      this.validationCheck.classList.remove('invalid')
    }
  }

  allFormAttachedFields() {
    return !this.fileElements.some( input => input.dataset.fileAttached === 'false') &&
      this.validationCheck.checked
  }

  renderInvalidFields() {
    this.fileElements.forEach( input => {
      if(input.dataset.fileAttached === 'false') {
        let targetId  = input.id.replace('file-attachment', 'picture-frame')
                             .replace('address-image-field', 'picture-frame')
        const container = this.element.querySelector(`#${targetId}`)
        const target = container.querySelector('.content-docto')
        const errorLabel = container.querySelector('.error-label')
        target.classList.add('invalid')
        errorLabel.style.display = 'block'
      }
      if(!this.validationCheck.checked) {
        this.validationCheck.classList.add('invalid')
      }
    })
  }
}
