import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  updateList(_event) {
    if(this.writeTimer) { clearTimeout(this.writeTimer) }

    if(!this.element.querySelector('#spinner-loader')) {
      this.element.querySelector('.from-group')?.appendChild(this.createSpinner())
    }
    this.writeTimer = setTimeout(() => { this.element.submit() }, 1000)
  }

  createSpinner() {
    const element = document.createElement('div')
    element.role = 'status'
    element.id = 'spinner-loader'
    element.classList.add('spinner-border')
    element.style.width = '1rem'
    element.style.height = '1rem'
    element.style.opacity = '0.6'
    element.style.fontSize = '11px'
    return element
  }
}
