import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    persisted: Boolean
  }

  connect() {
    this.positionValue = this.element.querySelector('.position-input')?.value
    this.formContainer = this.element.closest('form')
  }

  update(event) {
    if(event.currentTarget.value === '') return

    this.#launchEvent()
  }

  #launchEvent() {
    const event = new CustomEvent('questionnaire:pageChanged')
    this.formContainer.dispatchEvent(event)
  }
}
