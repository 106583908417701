// El objetivo de esta clase es permitir el ordenamiento de los elementos de una lista
// mediante acctiones de drag and drop

export default class {
  #list
  #draggedItem
  #draggedIndex

  constructor(list) {
    this.#list = list
    this.#draggedItem = null
    this.#draggedIndex = null
  }

  start() {
    this.#bindDragStartEvent()
    this.#bindDragedEvent()
    this.#bindDragOverEvent()
    this.#bindDropEvent()
  }

  reorderList() {
    const items = this.#list.querySelectorAll('li:not(.d-none)')
    items.forEach((item, index) => {
      item.setAttribute('data-index', index + 1)
    })

    const customEvent = new CustomEvent('questionnaire:onquestionsSort', {
      bubbles: true
    })
    this.#list.dispatchEvent(customEvent);
  }

  #bindDragStartEvent() {
    this.#list.addEventListener('dragstart', e => {
      if (e.target.tagName !== 'LI') return

      this.#draggedItem = e.target
      this.#draggedIndex = parseInt(e.target.getAttribute('data-index'))
      setTimeout(() => e.target.classList.add('dragging'), 0)
    });
  }

  #bindDragedEvent() {
    this.#list.addEventListener('dragend', e => {
      e.target.classList.remove('dragging')
      this.#draggedItem = null
    })

  }

  #bindDragOverEvent() {
    this.#list.addEventListener('dragover', e => {
      e.preventDefault()
      const afterElement = this.#getDragAfterElement(this.#list, e.clientY)
      const placeholder = this.#list.querySelector('.placeholder')

      if (!afterElement || !placeholder) {
        this.#list.appendChild(this.#createPlaceholder());
      } else if (afterElement && placeholder && afterElement !== placeholder) {
        this.#list.insertBefore(placeholder, afterElement);
      }
    });
  }

  #bindDropEvent() {
    this.#list.addEventListener('drop', e => {
      e.preventDefault()
      const placeholder = document.querySelector('.placeholder')
      const newPosition = Array.from(this.#list.children).indexOf(placeholder)

      if (placeholder) {
        this.#list.removeChild(placeholder)
      }

      if (this.#draggedIndex !== newPosition) {
        this.#list.insertBefore(this.#draggedItem, this.#list.children[newPosition])
        this.reorderList()
      }
    });

  }

  // Aqui pasa gran parte de la magia por eso los comentarios
  // determina qué elemento de la lista se encuentra más cercano a la posición
  // vertical actual del puntero (el valor y) mientras se arrastra un elemento
  #getDragAfterElement(list, y) {
    // se obtienen los elementos que no estan siendo arrastrados
    const draggableElements = [...list.querySelectorAll('li:not(.dragging)')];

    // se encuentra el elemento posterior a la posicion del cursor
    return draggableElements.reduce((closest, child) => {
      const box = child.getBoundingClientRect();
      const offset = y - box.top - box.height / 2;

      // si el offset es negativo y mayor al offset del element mas cercano
      // actualiza la posicion del elemento mas cercano
      if (offset < 0 && offset > closest.offset) {
        return { offset: offset, element: child };
      } else {
        // Sino regresa el element mas cercano actual
        return closest;
      }
    }, { offset: Number.NEGATIVE_INFINITY }).element;
  }

  #createPlaceholder() {
    const placeholder = document.createElement('li');
    placeholder.classList.add('placeholder');
    return placeholder;
  }
}
