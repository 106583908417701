import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['company', 'driverLevelContainer', 'platform', 'stepOne', 'stepTwo', 'rating', 'trips', 'income',
                    'firstName', 'middleName', 'paternalSurname', 'maternalSurname', 'mobilePhone', 'email'
                   ]

  redBorder = 'solid 1px #ff3342'
  grayBorder = 'solid 2px #e5e4e1'
  orangeBorder = 'solid 2px #ffaa78'

  connect() {
    this.initVariables();

    this.platformTargets.forEach(platform => {
      platform.addEventListener('click', event => this.changePlatform(event))
    })

    this.element.querySelectorAll('.focusable').forEach(element =>{
      element.addEventListener('focusout', event => {
        this.verifyRequiredField(event.currentTarget)
      })
    })

    this.stepTwoTarget.style.display = 'none'
  }


  initVariables() {
    if (this.companyTarget.value) {
      this.platformTargets.forEach(platform => {
        if (platform.dataset.platform === this.companyTarget.value) {
          platform.classList.add('active');
        } else {
          platform.classList.remove('active');
        }
      });
    }

    this.toggleDriverLevelField();
  }

  changePlatform(event) {
    this.platformTargets.forEach(platform => {
      platform.classList.remove('active')
    })
    event.currentTarget.classList.add('active')
    this.companyTarget.value = event.currentTarget.dataset.platform
    this.toggleDriverLevelField()
    this.platformFieldOk()
  }

  toggleDriverLevelField(){
    this.driverLevelContainerTarget.style.display = this.companyTarget.value == 'didi' ? 'none' : 'block'
  }

  showStepTwo(){
    if(this.stepOneFieldsAreOk()){
      this.stepOneTarget.style.display = 'none'
      this.stepTwoTarget.style.display = 'block'
    }else{
      this.ratingTarget.scrollIntoView(false)
    }
  }

  showStepOne(event){
    event.preventDefault()
    this.stepOneTarget.style.display = 'block'
    this.stepTwoTarget.style.display = 'none'
  }

  stepOneFieldsAreOk(){
    let requiredFields = [this.ratingTarget, this.tripsTarget, this.incomeTarget]
    const validationChecks = requiredFields.reduce((result, field) => {
      result.push(this.verifyRequiredField(field))
      return result
    }, [])

    return (this.platformFieldOk() && !validationChecks.includes(false))
  }

  verifyRequiredField(field){
    let valid = true
    let errorLabel = field.parentNode.querySelector('.error-label ')
    if(errorLabel === null){
      errorLabel = field.parentNode.parentNode.querySelector('.error-label ')
    }
    if(field.value == ''){
      valid = false
      field.style.border = this.redBorder
      errorLabel.style.display = 'block'
    }else{
      field.style.border = 'none'
      errorLabel.style.display = 'none'
    }
    return valid
  }

  platformFieldOk(){
    const valid = this.companyTarget.value !== ''
    this.platformTargets.forEach(platform => {
      platform.style.border = platform.classList.contains('active') ? this.orangeBorder : ( valid ? this.grayBorder : this.redBorder )
    })
    return valid
  }

  submit(event){
    if(!this.stepTwoFieldsAreOk()){
      event.preventDefault()
    }
  }

  stepTwoFieldsAreOk(){
    let requiredFields = [this.firstNameTarget, this.paternalSurnameTarget, this.maternalSurnameTarget, this.mobilePhoneTarget, this.emailTarget]
    const validationChecks = requiredFields.reduce((result, field) => {
      result.push(this.verifyRequiredField(field))
      return result
    }, [])

    return !validationChecks.includes(false)
  }

  validateRating(e){
    let input = e.currentTarget
    const value = parseFloat(input.value);

    if (value < 0.0 || value > 5.0 || input.validity.patternMismatch || input.value.length > 3) {
      input.value = "";
    }
  }
}
