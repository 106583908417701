import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['selector']
  connect() {
    this.selectorTargets.forEach(element => {
      element.addEventListener('change', event => { this.updateTabs(event) })
    })
  }

  updateTabs(event) {
    document.getElementById(event.currentTarget.dataset.nextTarget).classList.toggle('d-none')
    document.getElementById(event.currentTarget.dataset.thisTarget).classList.toggle('d-none')
  }
}
