import BaseValidator from "./base_validator"

export default class FormatValidator extends BaseValidator {
  validate() {
    let valid = true
    let errorMessage = ''

    // Ruby gerex can not be executed in javascript
    const regexString = this.options.with.replace('(?-mix:','')
                      .replace(')','').replace('\\A','^')
                      .replace('\\z','$').replace('\\Z','$')
                      .replace(/^\// ,'').replace(/\/[a-z]*$/,'')
                      .replace(/\(\?#.+\)/,'').replace(/\(\?-\w+:/,'(')
                      .replace(/\s/,'')

    const regexp = new RegExp(regexString)
    if(!regexp.test(this.value)){
      valid = false
      errorMessage = this.errorMessages['invalid']
    }

    return { valid: valid, message: errorMessage }
  }
}
