import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"
import { destroy } from '@rails/request.js'
import { dataURLtoBlob } from '../helpers'
import ImageCompressor from '../utils/image_compressor'

export default class extends Controller {
  static targets = ['imageFrame', 'addButton', 'deleteButton', 'screenshotControls']
  static values = {
    pdfImage: String,
    validFormats: { type: Array, default: ['pdf', 'png', 'jpg', 'jpeg', 'webp'] }
  }

  connect() {
    this.errorLabel = this.element.querySelector('.error-label')
    this.fileInput = this.element.querySelector('input[type=file]')
    this.fileUploadEvent = new CustomEvent('form:changed', {});
    this.fileInput.addEventListener('input', (e) => {
      this.processAttachment(e)
      const submitButton = e.target.form.querySelector('input[type="submit"]');
      if (submitButton){
        submitButton.click()
      }
    })

    const defaultImage = this.imageFrameTarget?.querySelector('.default-image')
    if (defaultImage){
      this.progressContainer = this.imageFrameTarget?.parentElement.querySelector('.progress-container')
      if (this.progressContainer){
        this.progressBar  = this.progressContainer.querySelector('.progress .progress-bar')
        this.controlsSection = this.element.querySelector('.controls-section')
      }
      this.fileUploadEvent = new CustomEvent('form:changed', {});
      this.defaultImagePath = defaultImage.src
    }
  }

  async processAttachment(e) {
    let file = e.currentTarget.files[0]
    this.fileName = file.name
    if (this.errorLabel){
      if(!this.validFileType(file)) {
        this.errorLabel.textContent = 'Formato no permitido'
        this.errorLabel.style.display = 'block'
        return
      } else {
        this.errorLabel.textContent = 'Campo obligatorio'
        this.errorLabel.style.display = 'none'
      }
    }
    if(this.isAnImage(file)) {
      this.progressContainer?.classList.remove('d-none')
      const imageCompressor = new ImageCompressor(file, 0.98)
      file = await imageCompressor.compressImage()
    }
    if(this.fileSize(file) > 15) {
      return
    }
    this.callbackActions(file)
  }

  validFileType(file) {
    const type = file.type.split('/')[1]
    return this.validFormatsValue.includes(type)
  }

  isAnImage(file) {
    return file && file['type'].split('/')[0] === 'image';
  }

  callbackActions(file) {
    this.uploadImage(file)
  }

  showPhoto(file){
    const reader = new FileReader()
    const img = this.imageFrameTarget.querySelector('.default-image')
    this.errorLabel.style.display = 'none'
    this.imageFrameTarget.classList.remove('upload-area--invalid')
    if(this.isAnImage(file)) {
      this.imageFrameTarget.classList.add('upload-area--loaded')
      reader.onloadend = () => { img.src = reader.result }
      reader.readAsDataURL(file)
      img.classList.remove('d-none')
      img.alt = file.name
      img.width = 450
      img.classList.add('autoheight')
    } else {
      img.classList.remove('d-none')
      this.imageFrameTarget.classList.add('upload-area--loaded-pdf')
      img.src = this.pdfImageValue
    }
    document.body.dispatchEvent(this.fileUploadEvent)
  }

  uploadImage(file) {
    const url = this.fileInput.dataset.directUploadUrl
    const upload = new DirectUpload(file, url, this)
    upload.create((error, blob) => {
      if (error) {
        console.log(error)
      } else {
        this.appendFileIdToForm(blob.signed_id)
        this.hideAddButton()
        this.showDeleteButton(blob.signed_id)
        this.fileInput.setAttribute('data-file-attached', 'true')
        this.fileInput.value = null
        this.showPhoto(file)
        if (this.progressContainer){
          this.progressContainer.classList.add('d-none')
          this.progressBar.style.width = '0%';
        }
      }
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    if (this.progressContainer){
      request.upload.addEventListener("progress", event => {
          this.directUploadDidProgress(event)
      })
    }
  }

  directUploadDidProgress(event) {
      this.progressBar.style.width = `${event.loaded * 100 / event.total}%`;
      this.progressBar.textContent = `${(event.loaded * 100 / event.total).toFixed(1)}%`
  }

  appendFileIdToForm(blob_id) {
    // Add an appropriately-named hidden input to the form with a
    //  value of blob.signed_id so that the blob ids will be
    //  transmitted in the normal upload flow
    let form = document.querySelector('.file-upload-form')
    if(!form) {
      form = this.element
    }

    const hiddenField = document.createElement('input')
    hiddenField.setAttribute("type", "hidden")
    hiddenField.setAttribute("value", blob_id)
    hiddenField.name = this.fileInput.name
    form.appendChild(hiddenField)
  }

  showDeleteButton(blob_id) {
    const a = this.deleteButtonTarget
    const name = document.createElement('p')
    name.classList.add('short-text')
    name.innerHTML = this.fileName
    a.setAttribute("data-destroy-path", `/storage/${blob_id}`)
    a.setAttribute('data-action', 'click->file-upload#deleteUnattachedImage')
    a.classList.remove('d-none')
  }

  async deleteUnattachedImage(e) {
    e.preventDefault()
    const blobIdField = document.querySelector(`input[name='${this.fileInput.name}'][type=hidden]`)
    blobIdField.remove()
    const url = e.currentTarget.dataset.destroyPath
    const response = await destroy(url, {})
    if (response.ok) {
      this.imageFrameTarget.classList.remove('upload-area--loaded')
      this.imageFrameTarget.classList.remove('upload-area--loaded-pdf')
      this.deleteButtonTarget.classList.add('d-none')
      this.addButtonTarget.classList.remove('d-none')
      this.screenshotControlsTarget.classList.remove('d-none')
      this.fileInput.setAttribute('data-file-attached', 'false')
      const img = this.imageFrameTarget.querySelector('.default-image')
      img.src = this.defaultImagePath
    }
  }

  hideAddButton() {
    this.addButtonTarget?.classList?.add('d-none')
    this.screenshotControlsTarget?.classList?.add('d-none')
  }

  selectFile(e) {
    e.preventDefault()
    e.stopPropagation()
    if(this.fileInput.hasAttribute('capture')) {
      this.fileInput.removeAttribute('capture')
      this.fileInput.removeAttribute('accept')
    }

    this.fileInput.click()
  }

  fileSize(file) {
    return (file.size / 1024 / 1024 )
  }
}
