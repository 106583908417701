import { Controller } from "@hotwired/stimulus"
import { numberToWords } from '../helpers'

export default class extends Controller {
  connect() {
    this.label = this.buildTextLabel()
    this.element.addEventListener('keyup', (event) => {
      this.converToNumber(event)
    })
    this.element.insertAdjacentElement('afterend', this.label)
  }

  converToNumber(event) {
    let strNumber = event.currentTarget.value
    strNumber = strNumber.replace(/,/g, "").replace(/\$/g, "")

    let result = numberToWords(parseFloat(strNumber))
    this.label.textContent = result
  }

  buildTextLabel() {
    const element = document.createElement('p')
    element.textContent = ''
    return element
  }
}
